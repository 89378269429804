'use strict';
var base = require('core/util/loginPopup');
var debounce = require('lodash/debounce');

function togglePassword($this) {
    var input = $this.siblings($this.attr('toggle'));  

    // a hack to set the value of input when suggested from chrome
    input.val(input.val());

    if (input.val() !== '') {
      if (input.attr('type') === 'password') {
        input.attr('type', 'text');
      } else {
        input.attr('type', 'password');
      }
    }
}

base.showHidePassword = function () {
  var debounceTogglePassword = debounce(togglePassword, 500);
  $(document).on('click touchstart', '.show-pwd', function () {
    debounceTogglePassword($(this))
  });
};

module.exports = base;
