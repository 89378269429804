'use strict';

/*
Dynamic Yield Recommendation Strip

Dynamic Yield HTML template:
<div class="wrapper-content dynamic-yield-rec-strip">
	<div class="row slider-heading-section">
		<div class="text-center col-12 slider-heading">
			<span>${rec_title}</span>
		</div>
	</div>
	<div class="row best-seller-margin">
		<div class="col-12 recommendation-products hbc-slider recommendation-2 dynamic-yield-products">
${#Recommendation}
			<div class="tile-wrapper slot-tile tile-hide-inventory-message tile-hide-swatches tile-is-slot dynamic-yield-product" data-master-id="${group_id}" data-variant-id="${sku}" data-color="${color}">
		<!-- ${name} -->
			</div>
${/Recommendation}
		</div>
	</div>
</div>
*/

// Randomize array order for testing.
function shuffleArray(array) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
}

function replacePlaceholders($recStrip, HTML, testMode) {
	var $placeholders = $recStrip.find('.dynamic-yield-products .dynamic-yield-product');
	var $slider = $recStrip.find('.dynamic-yield-products');
	var visibleTileCount = 0;

	// Loop through the response and copy tile HTML into placeholder. Each tile is wrapped in .dy-tile-container.
	$(HTML).filter('.dy-tile-container').each(function(i, e) {
		var $tileHTML = $(this);
		var tileID = $tileHTML.data('id'); // Product ID of tile in the response.

    if ($tileHTML.find('.displayProductTile').length > 0) {
      // If a product master is offline or restricted, 'product/gridTile.isml' renders this HTML instead of a tile:
      // <span class="displayProductTile" data-displaytile="false" data-pid="${pdict.product.masterProductID}"></span>
      console.debug('DY: Tile is hidden - ' + tileID);
    } else {
      if (testMode) {
        console.debug('DY: Replaced placeholder #' + i + ' - ' + tileID + ' (test mode)');
        $($placeholders.get(i)).html($tileHTML);
        visibleTileCount++;
      } else {
        // Find placeholder with matching ID. Replace HTML with response tile.
        var $tileStub = $placeholders.filter('[data-variant-id="' + tileID + '"]');
        if ($tileStub) {
          console.debug('DY: Replaced placeholder - ' + tileID);
          $tileStub.html($tileHTML);
          visibleTileCount++;
        }
      }
    }
	});

	// Remove slides which were not replaced. The process is slightly different if Slick Carousel has already been initialized.
	if ($slider) {
		if ($slider.hasClass('slick-initialized')) {
			console.debug('DY: Slick carousel initialized...');
			var $allSlides = $slider.find('.slick-slide');
			$($placeholders.get().reverse()).each(function() { // Backwards, because slick remove is weird.
				var $contents = $(this).find('.dy-tile-container');
				if ($contents.length == 0) {
					// Placeholder does not have content because product tile is missing, offline, sold out, etc.
					var variantID = $(this).data('variant-id');
					var $slide = $(this).parents('.slick-slide');
					if ($slide.length) {
						var index = $allSlides.index($slide);
						$slider.slick('slickRemove', index);
						console.debug('DY: Removed slide #' + index + ' - ' + variantID);
					}
				}
			});
		} else {
			// Mobile
			console.debug('DY: Slick carousel not initialized or mobile...');
			$($placeholders.get().reverse()).each(function() {
				var $contents = $(this).find('.dy-tile-container');
				if ($contents.length == 0) {
					var variantID = $(this).data('variant-id');
					$(this).remove();
					console.debug('DY: Removed empty placeholder - ' + variantID);
				}
			});
		}
	}

	return visibleTileCount;
}

module.exports = function () {
	// Event 'dyrecstrip' is triggered by Dynamic Yield on load.
	$(document).on('dyrecstrip', function(event, display) {
		if (window.SitePreferences.dynamicYieldEnabled != true) {
			return;
		}

		// URL to DynamicYield-DYTiles controller for rendering product tile HTML.
		var DYTiles = window.Urls.DYTiles;

		// Loop because there maybe be more than one DY recommendation strip.
		$('.dynamic-yield-rec-strip').not('.tiles-loaded').each(function() {
			// Hide rec strip until tiles are loaded.
			$(this).find('.slider-heading-section .slider-heading').addClass('d-none');

			// Dynamic Yield product tile placeholders.
			var $dyTilePlaceholders = $(this).find('.dynamic-yield-products .dynamic-yield-product');

			// Build product array so we can load tiles.
			var productArr = [];
			var productArrDebug = [];
			$dyTilePlaceholders.each(function() {
				var $dyTilePlaceholder = $(this);
				var masterID = $dyTilePlaceholder.data('master-id');
				var variantID = $dyTilePlaceholder.data('variant-id');
				var color = $dyTilePlaceholder.data('color');
				productArr.push(variantID); // Use variant ID so different colors can be show.
				productArrDebug.push({
					'variant': variantID,
					'master': masterID,
					'color': color,
				})
			});
			console.debug('DY: Recommendations found...', productArrDebug);

			// For testing only
			var testMode = false;
			if (typeof window.SitePreferences.dynamicYieldMockProducts == "string" && window.SitePreferences.dynamicYieldMockProducts.length > 0) {
				productArr = (window.SitePreferences.dynamicYieldMockProducts).split(',');
				if (productArr.length > 1) {
					shuffleArray(productArr);
				}
				testMode = true;
				console.debug('DY: Test mode...');
			}

			if (productArr.length) {
				// Call DynamicYield-DYTiles?pids=... to load product tile HTML.
				console.debug('DY: Loading rendered tiles...');
				$.ajax({
					url: DYTiles + '?pids=' + productArr.join(','),
					type: 'get',
					dataType: 'html',
					context: this, // Context is .dynamic-yield-rec-strip
					success: function (response) {
						var visibleTileCount = replacePlaceholders($(this), response, testMode);
						if (visibleTileCount > 0) {
						$(this).find('.slider-heading-section .slider-heading').removeClass('d-none');
						$(this).parents('.dyPlaceholder').removeClass('dyPlaceholder');
						// Reinit carousel and load images
							try {
								window.hbcSlider.hbcSliderInit('recommendation-products');
								window.lazyload.lazyLoadImages();

								// Mobile fix: set carousel to first slide.
								var $slider = $(this).find('.dynamic-yield-products');
								if ($slider.hasClass('slick-initialized')) {
									console.debug('DY: Set carousel to first slide...');
									if ($(this).find('.dynamic-yield-products').length) {
										$(this).find('.dynamic-yield-products').slick('slickGoTo', 0, true);
									}
								} else {
								  // Mobile
								  console.debug('DY: Set scroll left to first slide...');
								  $(this).find('.dynamic-yield-products').scrollLeft(0);
								}
							} catch (e) {}
						} else {
							$(this).parents('.dyPlaceholder').addClass('d-none');
						}
					}
				});
			} else {
				$(this).parents('.dyPlaceholder').addClass('d-none');
			}
			// Add class so we do not initalize twice
			$(this).addClass('tiles-loaded');
		});
	});
};
