'use strict';

const createPopper = require('@popperjs/core');

// allow specific tooltip reinit without taxing entire DOM
function tooltipIterator(toggletip) {
  var trigger;
  var content;
  var liveRegion;
  var arrow;
  var liveRegionContent;
  var popperInstance;

  if (toggletip.dataset.initialized) {
    return;
  }

  trigger = toggletip.querySelector('button.tooltip-info, .tooltip-btn');
  content = toggletip.querySelector('.tooltip-content');
  liveRegion = toggletip.querySelector('div[role="status"]');

  // eslint-disable-next-line no-param-reassign
  toggletip.dataset.initialized = true;

  if (!trigger || !content) {
    return;
  }

  if (!liveRegion && trigger) {
    liveRegion = document.createElement('div');
    liveRegion.setAttribute('role', 'status');
    trigger.insertAdjacentElement('afterend', liveRegion);
  }

  liveRegion.style.display = 'none';

  /* Add an element for the arrow */
  arrow = document.createElement('div');
  arrow.dataset.popperArrow = true;

  liveRegionContent = document.createElement('div');
  liveRegionContent.classList.add('content');

  liveRegion.insertAdjacentElement('beforeend', liveRegionContent);
  liveRegion.insertAdjacentElement('beforeend', arrow);

  /* Handle dynamic positioning */
  popperInstance = createPopper.createPopper(toggletip, liveRegion, {
    placement: 'top',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrow,
          padding: 2
        }
      },
      {
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      },
      {
        name: 'computeStyles',
        options: {
          gpuAcceleration: false // true here was causing some intermittent horizontal scrolling on smaller screens
        }
      }
    ]
  });

  /**
   * Closes the tooltip
   */
  function closeTooltip() {
    liveRegion.style.display = 'none';
    liveRegionContent.innerHTML = '';
    trigger.dataset.tooltipOpen = false;
    document.removeEventListener('click', checkForOutsideClick);
    $('body').hasClass('tooltipOpened') ? $('body').removeClass('tooltipOpened') : '';
  }

  /**
   * Checks for clicks outside the tooltip and closes them.
   * @param {Event} e The click event
   */
  function checkForOutsideClick(e) {
    if (!toggletip.contains(e.target)) {
      closeTooltip();
    }
  }

  // Toggle the message
  if (trigger) {
    var eventType = $(trigger).hasClass('saks-tooltip-info') ? 'mouseenter' : 'click';
    var isRewardPointsTooltip = $(trigger).closest('.saks-first-card.bfx-remove-element').length != 0;
    if ($(trigger).hasClass('saks-tooltip-info') && !isRewardPointsTooltip) {
      trigger.addEventListener('mouseleave' , function (){
        closeTooltip();
      });
    }
    trigger.addEventListener(eventType, function () {
      // Close the tooltip if it is open
      if (trigger.dataset.tooltipOpen === 'true') {
        closeTooltip();
        return;
      }
      // Populate the tooltip.
      liveRegionContent.innerHTML = '';
      window.setTimeout(function () {
        liveRegionContent.innerHTML = '<div class="tooltip-content">' + content.innerHTML + '</div>';
        document.addEventListener('click', checkForOutsideClick);
        liveRegion.style.display = 'block';
        popperInstance.update();
      }, 100);
      trigger.dataset.tooltipOpen = true;
      $('body').addClass('tooltipOpened');
    });
  }

  // Remove toggletip on ESC
  if (trigger) {
    toggletip.addEventListener('keydown', function (e) {
      if ((e.keyCode || e.which) === 27) {
        liveRegion.innerHTML = '';
      }
    });
  }
}

/**
 * Initialize tooltip.
 */
function tooltipInit() {
  $('html.veiled').removeAttr('style');
  // Get all the toggletip buttons
  var toggletips = document.querySelectorAll('.custom-tooltip');

  if (toggletips.length > 0) {
    // Iterate over them
    Array.prototype.forEach.call(toggletips, tooltipIterator);
  }
}

module.exports = {
  tooltipInit: tooltipInit
};
