'use strict';

/**
 * Collapse comments and whitespace in empty content slots
 * So they can be targeted in CSS properly with :empty
 */
function collapseEmptySlots() {
  const slots = document.querySelectorAll('.home-slots');
  if (!slots.length) {
    return;
  }
  Array.from(slots).forEach(slot => {
    if (!slot.childElementCount) {
      slot.innerHTML = '';
    }
  });
}

module.exports = {
  collapseEmptySlots: collapseEmptySlots
};
