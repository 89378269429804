/**
 * Detects whether or not a user has a touchscreen as best we can figure.
 * Borrowed from https://patrickhlauke.github.io/touch/touchscreen-detection/
 * See that page for caveats
 * @return {boolean} True if touchscreen is detected, otherwise false
 */
const isTouchscreen = () => {
  if (window.PointerEvent && 'maxTouchPoints' in navigator) {
    if (navigator.maxTouchPoints > 0) {
      return true;
    }
  } else {
    // no Pointer Events...
    if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
      // check for any-pointer:coarse which mostly means touchscreen
      return true;
    } else if (window.TouchEvent || 'ontouchstart' in window) {
      // last resort - check for exposed touch events API / event handler
      return true;
    }
  }
  return false;
};

module.exports = isTouchscreen;
