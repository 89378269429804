function showSearchOverlay() {
  var $body = $('body');
  var searchOverlayTemplate = `
    <div class="header-search-overlay active preview">
      <div class="scrim">
        <div class="spinner2"><div class="dot3"></div><div class="dot4"></div></div>
      </div>
    </div>`;

  // add overlay markup on user interaction
  $body.append(searchOverlayTemplate);

  // we need 100ms timeout to show spinner and for the right a11y behavior
  setTimeout(() => {
    $('<script>').attr('src', $body.data('search-src')).appendTo('head');
  }, 100);
}

if (window.FABuild) {
  showSearchOverlay();
} else {
  $('.search-field').one('focus', showSearchOverlay);
}
