'use strict';

let bodyObserver;

module.exports = {
  /**
   * Stops the page from scrolling.
   */
  preventPageScroll: function (isMenu) {
    const style = document.body.style;
    const scrollY = window.scrollY;
    style.position = 'fixed';
    style.top = `-${scrollY}px`;
    style.left = '0';
    style.right = '0';

    const enforceScrollbarVisibility = () => {
      if (document.body.scrollHeight > window.innerHeight) {
        style.overflowY = isMenu ? 'hidden' : 'scroll'; // Force scrollbar track to remain to prevent width shift.
      }
    };

    const callback = function (mutationsList) {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style' && document.body.style.overflowY !== 'scroll') {
          enforceScrollbarVisibility();
        }
      }
    };

    bodyObserver = bodyObserver || new MutationObserver(callback);
    bodyObserver.observe(document.body, { attributes: true });

    enforceScrollbarVisibility();
  },

  /**
   * Allows the page to scroll, resetting the scroll to where it was before if applicable.
   */
  allowPageScroll: function () {
    const style = document.body.style;
    const scrollY = style.top;
    ['position', 'top', 'left', 'right', 'overflowY'].forEach(property => (style[property] = ''));
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    bodyObserver.disconnect();
  }
};
