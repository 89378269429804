'use strict';

function handleClick() {
  // Make sure that User is external using SFCC Geolaction along with Salesfloor Geolocation
  $('.wishi-options-view-2').removeClass('d-none');
  $('.live-chat-view-2').addClass('d-none');
  $('.live-chat-view-3').addClass('d-none');
  $('.live-chat-view-4').addClass('d-none');
  $('#wishi-chat-inline').addClass('d-none');
  $('.chat-img').toggleClass('d-none');
  $('.chat-img-cross').toggleClass('d-none');
  $('body').toggleClass('chat-overlay');
  $('.wishi-options-content').toggleClass('d-none');

  setTimeout(() => {
    if ($('.embeddedServiceHelpButton .helpButton .uiButton.helpButtonDisabled').length > 0) {
      $('.chat-service-links .customer-service-chat-link').addClass('live-chat-offline');
    } else if ($('.embeddedServiceHelpButton .helpButton .uiButton.helpButtonEnabled').length > 0) {
      $('.chat-service-links .customer-service-chat-link').addClass('live-chat-online');
    }
  }, 2000);
}

function addEventListeners() {
  $('.wishi-customer-service').on('click', function () {
    $('.wishi-options-view-2').toggleClass('d-none');
    $('.live-chat-view-3').toggleClass('d-none');
  });

  $('.delayed-chat-options .customer-service').on('click', function () {
    $('.wishi-options-view-2').toggleClass('d-none');
    $('.live-chat-view-3').toggleClass('d-none');
  });

  $('.delayed-chat-options .customer-service-call-link').on('click', function () {
    $('.live-chat-view-3').toggleClass('d-none');
    $('.live-chat-view-4').toggleClass('d-none');
  });

  $('.delayed-chat-options .call-customer-service').on('click', function () {
    $('.live-chat-view-3').toggleClass('d-none');
    $('.live-chat-view-4').toggleClass('d-none');
  });
}

function chatHandler(event) {
  var isCustomEvent = event.type === 'chat:initHandler';
  var wishiOptionsContainer = $('.wishi-options-content');
  var url = wishiOptionsContainer.data('optionsUrl');
  if (!wishiOptionsContainer.data('fetched')) {
    $.ajax({
      url: url,
      type: 'get',
      success: data => {
        $('.wishi-options-content').html($.parseHTML(data, document, true));
        if (!isCustomEvent) {
          handleClick();
        }
        addEventListeners();
        wishiOptionsContainer.data('fetched', true);
      }
    });
  } else if (!isCustomEvent) {
    handleClick();
  }
}

module.exports = {
  init: function () {
    var wishiOptionsContainer = $('.wishi-options-content');

    if (wishiOptionsContainer.data('fetched')) {
      addEventListeners();
    }

    $('.wishi-icon-btn').on('click', chatHandler);
    $('body').on('chat:initHandler', chatHandler);
  }
};
