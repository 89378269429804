var login = require('../login/login');
var cookiesUtil = require('../components/utilhelper');
/**
 * Generates the modal window on the first call.
 *
 * @param {Object} data - render data
 */
function appendModalHtmlElement(data) {
  if ($('#signInApplyModal').length !== 0) {
    $('#signInApplyModal').empty();
  }
  var htmlString =
    '<!-- Modal -->' +
    '<div class="modal-dialog">' +
    '<div class="modal-content">' +
    '<div class="modal-body">' +
    '<div class="cancel-icon">' +
    '<button type="button" class="close svg-svg-22-cross svg-svg-22-cross-dims" data-dismiss="modal" aria-label="Close Modal Box"></button>' +
    '</div>' +
    '<div class="no-gutters modal-row align-items-start modal-contents">' +
    '<div class="modal-column">' +
    data +
    '</div>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '</div>';
  if ($(document).find('#signInApplyModal').length) {
    $(document).find('#signInApplyModal').append(htmlString);
  }
}

/**
 * open login model
 * @param {string} url url to be open with
 */
function openLoginPopup(url) {
  $('body').spinner().start();
  $.ajax({
    url: url,
    dataType: 'html',
    success: function (data) {
      if (data.redirect) {
        window.location.href = data.redirect;
      } else {
        appendModalHtmlElement(data);
        $('#signInApplyModal').modal('hide');
        setTimeout(function () {
          $('body').spinner().stop();
          $('#signInApplyModal').modal('show');
          if ($('.benefits-main-div .icons-container').length === 1) {
            $('.benefits-main-div').addClass('single-icon-container');
          } else if ($('.benefits-main-div .icons-container').length === 0) {
            $('.benefits-main-div').addClass('no-icon-content');
          }
          if ($('#signInApplyModal .new-user-section').length > 0) {
            $('#signInApplyModal .new-user-section').removeClass('invisible');
          }
        }, 200);
      }
    },
    error: function () {
      $('body').spinner().stop();
    }
  });
}

/**
 * init for SignIn Model
 */
function initSignIn() {
  // Remove the href value from links that should open the modal sign in window.
  // This is a temporary fix until the content asset is updated.
  var saksApplyLinks = document.querySelectorAll('.js-apply-for-saks');
  if (saksApplyLinks) {
    Array.prototype.slice.call(saksApplyLinks).forEach(function (link) {
      if (link.hasAttribute('href')) {
        link.removeAttribute('href');
      }
    });
  }

  $('body').on('click', '.js-apply-for-saks', function (event) {
    event.preventDefault();
    if (cookiesUtil.getCookie('bfx.country') === 'US') {
      if ($('.user-links .popover.popover-bottom').hasClass('logged-out-user')) {
        openLoginPopup($(this).data('url'));
        // If simple login just redirect for the logged in url
      } else if ($(this).hasClass('simple-login')) {
        window.location.href = $(this).data('logged-in-url');
      } else {
        var url = $(this).data('logged-in-url');
        $('body').spinner().start();
        $.ajax({
          url: url,
          dataType: 'json',
          success: function (data) {
            $('body').spinner().stop();
            if (data.redirectUrl) {
              window.location.href = data.redirectUrl;
            }
          },
          error: function (data) {
            $('body').spinner().stop();
          }
        });
      }
    }
  });
}

/**
 * Overrides for content assets
 */
function contentOverride() {
  // Replace the original href with the footer element value.
  // This is a temporary fix until the content asset is updated.
  var footer = document.querySelector('.footer-apply-for-saks');
  if (footer && footer.getAttribute('data-url')) {
    footer.setAttribute('href', footer.getAttribute('data-url'));
  }

}

module.exports = {
  initSignIn: initSignIn,
  contentOverride: contentOverride
};
