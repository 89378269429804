/**
 * Adds the logged in user's email address as a query parameter to OneTrust privacy policy links on the page.
 * 
 * Checks if the user is logged in by looking for the 'logged-in-user' class on the user links element.
 * Gets the user's email from the 'useremail' data attribute on the user links element.
 * Loops through and updates the href attributes for:
 * - Links in the policy text element
 * - Limit use of sensitive info footer link 
 * - Privacy policy page OneTrust links
 * 
 * This allows passing the user's email to OneTrust for consent/preference tracking.
 */

/**
 * Appends the user's email address as a query parameter to the given element's href attribute.
 * 
 * @param {string} userEmail - The logged in user's email address
 * @param {jQuery} element - The element whose href should be updated 
 */
function addUserEmailToOneTrustLinks(userEmail, element) {
    if (userEmail && element && element.length) {
        var url = new URL(element.attr('href'));
        url.searchParams.set('emailAddress', userEmail);
        url.searchParams.set('loggedIn', 'true');

        element.attr('href', url.toString());
    }
}


/**
 * Adds the user's email as a query parameter to OneTrust links inside the 
 * privacy policy modal, retrying until the links are available.
 * 
 * @param {string} userEmail - The logged in user's email address
 * @param {string} element - Selector for the policy text element containing OneTrust links
 */
function waitForOneTrustModalBeInjected(userEmail, element) {
    var tryCount = 0;
    var waitOneTrustModal = setInterval(function () {
        if ($(element).length) {
            // Loop through anchor tags
            $(element).find('a').each(function () {
                // Append email querystring to href
                addUserEmailToOneTrustLinks(userEmail, $(this));
            });
            clearInterval(waitOneTrustModal);
        } else {
            if (tryCount >= 100) {
                clearInterval(waitOneTrustModal);
            }
            tryCount += 1;
        }
    }, 100);
};

// Get user email 
const userEmail = window.Resources.customerEmail;

if (userEmail) {

    const policyTextEl = '#pc-policy-text';
    const limitUseSenstiveInfoFooterEl = $('#limitUseSenstiveInfoFooter');
    const privacyPolicyRequestToKnowOrAccess = $('.privacyPolicyRequestToKnowOrAccess');
    const privacyPolicyRequestToDelete = $('.privacyPolicyRequestToDelete');
    const privacyPolicyRequestToCorrect = $('.privacyPolicyRequestToCorrect');
    const privacyPolicyRequestToOptOut = $('.privacyPolicyRequestToOptOut');
    const privacyPolicyRequestToLimitUse = $('.privacyPolicyRequestToLimitUse');

    //Consent Modal Links
    waitForOneTrustModalBeInjected(userEmail, policyTextEl);

    // Limit Use of Sensitive Information Footer Link
    addUserEmailToOneTrustLinks(userEmail, limitUseSenstiveInfoFooterEl);

    //Privacy Policy Page One Trust Links
    addUserEmailToOneTrustLinks(userEmail, privacyPolicyRequestToKnowOrAccess);
    addUserEmailToOneTrustLinks(userEmail, privacyPolicyRequestToDelete);
    addUserEmailToOneTrustLinks(userEmail, privacyPolicyRequestToCorrect);
    addUserEmailToOneTrustLinks(userEmail, privacyPolicyRequestToOptOut);
    addUserEmailToOneTrustLinks(userEmail, privacyPolicyRequestToLimitUse);

}