'use strict';

/**
 * Bound to the mouseenter event on .product-tile elements,
 * It checks to see if there's focus on another product tile and blurs it if necessary.
 * @param {Event} evt The event object
 */
function hoverEnterTile(evt) {
  var target = evt.currentTarget;
  var focusable;
  if (!document.activeElement) {
    return;
  }

  // Shift focussed element in a product tile when we roll over another product tile.
  if ($(document.activeElement).closest('.product-tile').length && !target.contains(document.activeElement)) {
    focusable = $(target).find(':focusable:first');
    if (focusable.length) {
      focusable.focus();
    } else {
      document.activeElement.blur();
    }
  }
}

/**
 * Bound to the mouseleave event on .product-tile elements,
 * It blurs focussed elements if there are any within the tile.
 * @param {*} evt An event object
 */
function hoverExitTile(evt) {
  var target = evt.currentTarget;
  if (!document.activeElement) {
    return;
  }

  if ($(document.activeElement).closest('.product-tile').length && target.contains(document.activeElement)) {
    document.activeElement.blur();
  }
}

$('.product-grid, .recommendations, .wishlistItemCards, .wishlist-cart-slider')
  .on('mouseenter', '.product-tile', hoverEnterTile)
  .on('mouseleave', '.product-tile', hoverExitTile);
