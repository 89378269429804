'use strict';

/**
 * Checks to see if the screen is above a certian width.
 * @return {boolean} True if large screen, otherwise false
 */
function isDesktop() {
  return window.innerWidth > 1023;
}

/**
 * Sets the sticky column top, and if appropriate, the max-height as well.
 */
function initStickyColumn() {
  const stickyColumns = document.querySelectorAll('.sticky-column');
  const pageHeader = document.querySelector('.page-header');
  const headerBanner = document.querySelector('.header-banner');

  if (!stickyColumns.length || !pageHeader) {
    return;
  }

  const setDimensionsFor = (column, offset) => {
    const topPadding = 10;
    if (column.classList.contains('restrict-max-height')) {
      column.style.maxHeight = `calc(100vh - ${offset + topPadding}px)`;
    }
    column.style.top = `${offset + topPadding}px`;
  };

  const $document = $(document);

  const handleScrollDown = () => {
    if (!isDesktop()) {
      return;
    }
    stickyColumns.forEach(column => setDimensionsFor(column, 0));
  };

  const handleScrollUp = () => {
    if (!isDesktop()) {
      return;
    }
    const offset = pageHeader.offsetHeight - (headerBanner ? headerBanner.offsetHeight : 0);
    stickyColumns.forEach(column => setDimensionsFor(column, offset));
  };

  $document.on('page:scrollDown', handleScrollDown);
  $document.on('page:scrollUp', handleScrollUp);

  // Trigger it on page load.
  handleScrollDown();
}

module.exports = initStickyColumn();
