'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');

var updateMiniCart = true;

/** Constants */
const $body = $('body');

// Expose hoverintent in/out functions outside of hoverintent function
// so that they can be accessed by keyboard events
var focusIn = function focusIn(element) {
  if ($('.search:visible').length === 0) {
    return;
  }
  const $minicart = $('.minicart');
  const $minicartQuantity = $minicart.find('.minicart-quantity');
  const $minicartLink = $('.minicart-link');
  const $minicartPopover = $minicart.find('.popover');
  const $miniCartPopupContainer = $('.minicart-popup-container .popover');
  const $miniCartPopupContainerSticky = $('.minicart-popup-container-sticky .popover');
  const $mainMenu = $body.find('.main-menu');
  var _element;
  var url = $minicart.data('action-url');
  var action = $('.page').data('action');
  if(action === "Home-Show"){
    if(!document.getElementById('miniCartScript')){
      var miniCartScript = document.createElement("script");
      miniCartScript.defer = true;
      miniCartScript.type = "text/javascript";
      miniCartScript.id = "miniCartScript"
      miniCartScript.src = $('.minicart').data('script-url');
      $("#mainScript").after(miniCartScript);
    }
  }
  if(action !== "Cart-Show" && !document.getElementById('cartScript')){
      var cartScript = document.createElement("script");
      cartScript.defer = true;
      cartScript.type = "text/javascript";
      cartScript.id = "cartScript"
      cartScript.src = $('.minicart').data('cart-script-url');
      $("#mainScript").after(cartScript);
  }
  var count = parseInt($minicartQuantity.text(), 10);
  var stkyHeight = parseInt($('.menu-wrapper').height()) - 11; // eslint-disable-line
  var hideOverflowY = function hideOverflowY() {
    if(window.innerWidth >= 1024) {
      var topScroll = window.pageYOffset || document.documentElement.scrollTop;
      var leftScroll = window.pageXOffset || document.documentElement.scrollLeft;
      // if scroll happens, set it to the previous value
      window.onscroll = function() {
        window.scrollTo(leftScroll, topScroll);
      }
  } else {
    $body.css({ 'overflow-y': 'hidden' });
  }

  };

  $minicartLink.removeClass('minicart-count-zero');
  if (!(count > 0)) {
    $minicartLink.addClass('minicart-count-zero');
  }

  if (count > 0 && $minicartPopover.filter('.show').length === 0) {
    $(element.relatedTarget).focus();
    hideOverflowY();
    _element = element || $minicart;

    // Swap Minicart Content for Duplicate content
    if ($mainMenu.hasClass('fixed') && $miniCartPopupContainer.text().length > 0) {
      $miniCartPopupContainerSticky.html($miniCartPopupContainer.html());
      $miniCartPopupContainer.empty();
    } else {
      if (!$mainMenu.hasClass('fixed') && $miniCartPopupContainerSticky.text().length > 0) {
        $$miniCartPopupContainer.html($miniCartPopupContainerSticky.html());
        $miniCartPopupContainerSticky.empty();
      }
    }

    // Hide the chat widget if it's open
    if ($body.hasClass('chat-overlay')) {
      $('.wishi-icon-btn').trigger('click');
    }

    if (!updateMiniCart) {
      $minicartPopover
        .addClass('show')
        .css('top', $minicart.outerHeight() + 3);
      if ($mainMenu.hasClass('fixed')) {
        $minicartPopover.addClass('show').css('top', stkyHeight);
      }
      setMiniCartScrollHeight();
      $body.trigger('adobeTagManager:openMiniCart', _element);
      return;
    }

    $minicartPopover
      .addClass('show')
      .css('top', $minicart.outerHeight() + 3);
    if ($mainMenu.hasClass('fixed')) {
      $minicartPopover.addClass('show').css('top', stkyHeight);
    }
    $minicartPopover.spinner().start();
    $.get(url, function (data) {
      // Line Item is 0, don't popup the minicart.
      var miniCartContainer = $(data).filter('.mini-cart-container');
      var noOFLineItem = parseInt(miniCartContainer.attr('data-numItems'));
      var minicartTitle = miniCartContainer.attr('data-title');
      var minicartLabel = miniCartContainer.attr('data-aria-label');
      $minicartQuantity.empty();
      if (noOFLineItem > 0) {
        $minicartQuantity.text(noOFLineItem);
      }
      $minicart.find('.minicart-link').attr({
        'aria-label': minicartLabel,
        title: minicartTitle
      });
      $minicartPopover.empty();
      if (noOFLineItem > 0) {
        $minicartPopover.append(data);
        if ($mainMenu.hasClass('fixed')) {
          $miniCartPopupContainer.empty();
        } else {
          $miniCartPopupContainerSticky.empty();
        }
        updateMiniCart = false;
        $.spinner().stop();
        $minicart.find('.product-summary').each(function () {
          $(this).scrollTop(1);
        });
        $body.trigger('adobeTagManager:openMiniCart', data);
        window.formFields.quantityHoverBorder();
        setMiniCartScrollHeight();
      } else {
        // Update the quantity and don't open up the mini cart.
        $.spinner().stop();
        $minicartPopover.removeClass('show');
      }
    });
  }

  // Hide Confirmation Modal if open
  $('.product-added-to-cart-modal.modal:visible').hide();
};

var focusOut = function focusOut() {
  // Code for hover intent out
  $('.minicart .popover').removeClass('show');
  $('.minicart-link').removeClass('minicart-count-zero');
  if(window.innerWidth >= 1024) {
    window.onscroll = function() {};
  } else {
    $body.css({
      'overflow-y': 'auto',
      'padding-right': ''
    });
  }
};

function minicartKeyboardEvents() {
  var minicartSelector = '.minicart';
  keyboardAccessibility(
    minicartSelector,
    {
      40: function ($wrapper) {
        // down
        var $children = $wrapper.find('.popover a, .popover button, .popover input');
        if (!$children.length) {
          return;
        }
        var focusIndex = $children.index(document.activeElement);
        if (focusIndex === -1 || focusIndex === $children.length - 1) {
          $children.eq(0).focus();
        } else {
          $children.eq(focusIndex + 1).focus();
        }
      },
      38: function ($wrapper) {
        // up
        var $children = $wrapper.find('.popover a, .popover button, .popover input');
        if (!$children.length) {
          return;
        }
        var focusIndex = $children.index(document.activeElement);
        if (focusIndex === 0 || focusIndex === -1) {
          $children.eq($children.length - 1).focus();
        } else {
          $children.eq(focusIndex - 1).focus();
        }
      },
      27: function () {
        // Escape
        document.querySelector('.minicart-link').focus();
        focusOut();
      }
    },
    function () {
      return $(minicartSelector);
    }
  );
}

/**
 * Mini cart Scroll Height
 */
function setMiniCartScrollHeight() {
  const $miniCartProductSummary = $('.minicart .product-summary');
  var winHt = $(window).outerHeight();
  var mcFooterHt = $('.minicart-footer').outerHeight();
  setTimeout(function () {
    if ($('.main-menu').is('.fixed')) {
      $miniCartProductSummary.scrollTop(0);
      mcFooterHt = $($('.minicart-footer')[1]).outerHeight();
      if (winHt < 786) {
        $miniCartProductSummary.css({
          'max-height': 410 - mcFooterHt
        });
      } else {
        $miniCartProductSummary.css({
          'max-height': 610 - mcFooterHt
        });
      }
    } else {
      $miniCartProductSummary.scrollTop(0);
      mcFooterHt = $($('.minicart-footer')[0]).outerHeight();
      if (winHt < 786) {
        $miniCartProductSummary.css({
          'max-height': 410 - mcFooterHt
        });
      } else {
        $miniCartProductSummary.css({
          'max-height': 610 - mcFooterHt
        });
      }
    }
  }, 200);
}

module.exports = function () {
  const $miniCart = $('.minicart');
  const $miniCartLink = $('.minicart-link');

  $miniCart.on('count:update', function (event, count) {
    if (count && $.isNumeric(count.quantityTotal)) {
      $miniCart.find('.minicart-quantity').text(count.quantityTotal);
      $miniCartLink.attr({
        'aria-label': count.cart.resources.minicartCountOfItems,
        title: count.cart.resources.minicartCountOfItems
      });
    }
  });

  /**
   * Hover intent functionality for minicart.
   * @param {element} element minicart bag.
   */
  function hoverIntentFunctionality(element) {
    var hoverTimeout;

    $(element).on('mouseenter', function() {
      clearTimeout(hoverTimeout);
      hoverTimeout = setTimeout(focusIn.bind(null, element), opts.interval);
    }).on('mouseleave', function() {
      clearTimeout(hoverTimeout);
      hoverTimeout = setTimeout(focusOut.bind(null, element), opts.timeout);
    });
  }

  var opts = {
    timeout: 500,
    interval: 0
  };
  if ($miniCart.length > 0) {
    hoverIntentFunctionality($miniCart[0]);
    hoverIntentFunctionality($miniCart[1]);
  }

  // Open the cart from the keyboard
  $miniCartLink.on('focusin', function (evt) {
    focusIn(evt.target);
  });

  // Close minicart when tabbing away from:
  // 1. cart container
  // 2. the cart icon itself
  $body.on('keyup', function (e) {
    // Halt if minicart popover is shown
    if (!$miniCart.find('.popover').hasClass('show')) {
      return;
    }

    let target;
    let code;
    let cartContainer = '.mini-cart-container';
    const TAB_KEY = 9;

    code = e.keyCode || e.which;

    if (code === TAB_KEY) {
      target = $(e.target);

      if (!target.parents(cartContainer).length && !target.hasClass('minicart-link')) {
        focusOut();
      }
    }
  });

  minicartKeyboardEvents();

  $body.on('touchstart click', function (e) {
    const activeModal = $miniCart.hasClass('show') || $('.popover').hasClass('show');
    if (activeModal && ($miniCart.has(e.target).length <= 0 || $('.popover').has(e.target).length <= 0)) {
      $miniCart.find('.popover').removeClass('show');
      $body.css({ 'overflow-y': 'auto' });
    }
  });

  /* $('.minicart').on('mouseleave', function (event) {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $body.hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
    });*/

  $body.on('change', '.minicart .quantity', function () {
    if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
      location.reload();
    }
  });
  $('body').on('product:afterAddToCart', function () {
    updateMiniCart = true;
  });
  $('body').on('cart:update', function () {
    updateMiniCart = true;
  });
};
