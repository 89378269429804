'use strict';

var cookieUtil = require('core/components/utilhelper');

if (cookieUtil.getCookie('emailsignup')) {
  $('#gatedDeclineMessage').modal('show');
} else {
  var $body = $('body');
  var welcomeModalUrl = $body.data('welcome-modal-url');

  if (welcomeModalUrl) {
    $.get($body.data('welcome-modal-url'), data => {
      $body.append(data);
    });
  }
}
if ($('.benefits-main-div .icons-container').length === 1) {
  $('.benefits-main-div').addClass('single-icon-container');
} else if ($('.benefits-main-div .icons-container').length === 0) {
  $('.benefits-main-div').addClass('no-icon-content');
}
if ($('.account-login-page .new-user-section').length > 0) {
  $('.account-login-page .new-user-section').removeClass('invisible');
}
