'use strict';

var atmHelper = require('core/atm/atmHelper');
/**
 * Updates card result in customer profile
 * @param {Object} cardResult - render data
 * @returns {Boolean} data - render data
 */
function updateApplicationHistory(cardResult) {
    var url = window.Urls.updateApplicationHistory;
    var requestBody = {};
    if (cardResult) {
        if (cardResult.result === 'APPROVED' || cardResult.result === 'ACCOUNT_EXISTS') {
            requestBody.result = cardResult.result;
            requestBody.callId = cardResult.callId;
            requestBody.decisionFull = cardResult.decisionFull || cardResult.message;
        } else if (cardResult.result === 'PENDING' || cardResult.result === 'DECLINED') {
            requestBody.result = cardResult.result;
            requestBody.decisionFull = cardResult.decisionFull || cardResult.message;
        } else if (cardResult.result === 'ERROR') {
            requestBody.result = cardResult.result;
            requestBody.decisionFull = cardResult.error;
        }
    }
    if (requestBody && url) {
        $('body').spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: { body: JSON.stringify(requestBody) },
            success: function (data) {
                $('body').spinner().stop();
                if (!data.success) {
                    return false;
                } else {
                    return true;
                }
            },
            error: function (err) {
                $('body').spinner().stop();
                return false;
            }
        });
    }
}
/**
 * @description method triggers the Bread-AccountLookUp route to get card details and 
 * update the card details in customer wallet
 * @param {Object} cardResult - render data
 * @returns {url} redirectUrl - redirected to account page
 */
function accountLookup(cardResult) {
    var url = window.Urls.accountLookup;
    if (url !== '' && url !== 'undefined' && cardResult && cardResult.callId) {
        $('body').spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: { callId: cardResult.callId },
            success: function (data) {
                $('body').spinner().stop();
                if (data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                }
            },
            error: function (err) {
                $('body').spinner().stop();
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
            }
        });
    }
}


module.exports = function () {
    if ('BreadPayments' in window) {
        window.BreadPayments.on("CARD:RECEIVE_APPLICATION_RESULT", (cardResult) => {
            // Handle Application Result
            if (cardResult && cardResult.result) {
                switch (cardResult.result) {
                    case 'APPROVED':
                        updateApplicationHistory(cardResult);
                        accountLookup(cardResult);
                        break;
                    case 'PENDING':
                    case 'ACCOUNT_EXISTS':
                    case 'DECLINED':
                    case 'ERROR':
                        updateApplicationHistory(cardResult);
                        break;
                }
                // custom event saksCardSubmit to track application results
                $('body').trigger('adobeTagManager:saksCardSubmit', cardResult);
            }
        });
        //Add classes to bread placement after it's rendered to make it look like a button 
        window.BreadPayments.on("CARD:TRACK_RENDER_PLACEMENT_EVENT", () => {
            $('.ep-text-placement').addClass('link-cta btn-secondary preapproval');
        });
    }
};
