'use strict';

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
  if (!$('.tracking-consent.consent').data('caonline')) {
    return;
  }

  const $trackingConsent = $('.tracking-consent');
  var urlContent = $trackingConsent.data('url');
  var urlAccept = $trackingConsent.data('accept');
  var urlReject = $trackingConsent.data('reject');
  var textYes = $trackingConsent.data('accepttext');

  var htmlString =
    '<!-- Modal -->' +
    '<div class="modal show" id="consent-tracking" role="dialog" style="display: block;">' +
    '<div class="modal-dialog">' +
    '<!-- Modal content-->' +
    '<div class="modal-content">' +
    '<div class="modal-header">' +
    '<div class="button-wrapper">' +
    '<button aria-label="Close cookie" type="button" class="close svg-svg-22-cross svg-svg-22-cross-dims" data-url="' +
    urlReject +
    '">' + // eslint-disable-line
    '</button>' +
    '</div>' +
    '</div>' +
    '<div class="modal-body"></div>' +
    '<div class="modal-footer">' +
    '<div class="button-wrapper">' +
    '<button role="button" class="affirm btn btn-primary" data-url="' +
    urlAccept +
    '">' +
    textYes +
    '</button>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '</div>';
  $.spinner().start();
  $('body').append(htmlString);

  $.ajax({
    url: urlContent,
    type: 'get',
    dataType: 'html',
    success: function (response) {
      $('#consent-tracking').find('.modal-body').html(response);
      trapFocus();
      $.spinner().stop();
    },
    error: function () {
      $('#consent-tracking').remove();
      $.spinner().stop();
    }
  });

  $('#consent-tracking .button-wrapper button').click(function (e) {
    e.preventDefault();
    var url = $(this).data('url');
    $.ajax({
      url: url,
      type: 'get',
      dataType: 'json',
      success: function () {
        releaseFocus();
        $('#consent-tracking').remove();
        $.spinner().stop();
      },
      error: function () {
        $('#consent-tracking').remove();
        $.spinner().stop();
      }
    });
  });
}

/**
 * Finds the first visible focusable element in the body and focuses on it
 */
function focusOnFirstVisibleElement() {
  $('#header-container').find(':focusable:visible:first').get(0).focus();
}

/**
 * Finds the next focusable element in the modal overlay and focuses that
 * @param {Element} currentItem DOM element representing the currenly focussed item.
 * @param {boolean} forward determines the direction of the next item to find
 */
function focusNextItem(currentItem, forward) {
  var focusableItems = document.querySelectorAll('#consent-tracking a, #consent-tracking button');
  var currentItemIndex = Array.prototype.indexOf.call(focusableItems, currentItem);
  var nextItem;
  if (currentItemIndex > -1) {
    switch (currentItemIndex) {
      case 0:
        nextItem = forward ? focusableItems[1] : focusableItems[focusableItems.length - 1];
        break;
      case focusableItems.length - 1:
        nextItem = forward ? focusableItems[0] : focusableItems[currentItemIndex - 1];
        break;
      default:
        nextItem = forward ? focusableItems[currentItemIndex + 1] : focusableItems[currentItemIndex - 1];
        break;
    }
    nextItem.focus();
  } else {
    focusableItems[0].focus();
  }
}

/**
 * Handles keyboard events to cycle through focusable items in the modal overlay
 * @param {Event} evt The event object
 */
function keepFocusInModal(evt) {
  var currentItem = evt.target;

  switch (evt.keyCode) {
    case 9: // Tab
    case 39: // Right Arrow
    case 40: // Down Arrow
      evt.preventDefault();
      focusNextItem(currentItem, true);
      break;
    case 37: // Left Arrow
    case 38: // Up Arrow
      evt.preventDefault();
      focusNextItem(currentItem, false);
      break;
    case 27: // Escape key
      evt.preventDefault();
      focusOnFirstVisibleElement();
  }
}

/**
 * Traps focus within the consent overlay
 */
function trapFocus() {
  var modal = $('#consent-tracking');
  var acceptButton = modal.find('button.affirm');

  // Default focusing on the accept button
  acceptButton.focus();
  modal.on('keydown', keepFocusInModal);
}

/**
 * Releases focus from the modal and kills the keydown listener
 */
function releaseFocus() {
  $('#consent-tracking').off('keydown', keepFocusInModal);
  focusOnFirstVisibleElement();
}

module.exports = function () {
  const $trackingConsent = $('.tracking-consent.consent');
  if ($('.consented').length === 0 && $trackingConsent.hasClass('api-true')) {
    showConsentModal();
  }

  if ($trackingConsent.hasClass('api-true')) {
    $trackingConsent.click(function () {
      showConsentModal();
    });
  }
};
