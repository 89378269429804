/* eslint-disable valid-jsdoc */
'use strict';

/**
 * Using this since IE is not supporting custom Event object
 * @param {string} eventName Event to dispatch
 * @param {object} detail object to send in event
 */
function dispatchCustomEvent(eventName, detail) {
  var evt = document.createEvent('CustomEvent');
  evt.initCustomEvent(eventName, false, false, detail);
  window.dispatchEvent(evt);
}

/**
 * Update ATM object for search suggestion
 * @param atmHelper
 * @returns
 */
function suggestedSearch(atmHelper) {
  atmHelper.suggestedSearch(function (search) {
    var detail = {
      search: search
    };
    dispatchCustomEvent('typeaheadSearchClick', detail);
  });
}

/**
 * Email Signup modal
 * @param atmHelper
 * @returns
 */
function emailSignupModal(atmHelper) {
  atmHelper.emailSignupModal(function (signup) {
    var visitor = {};
    var eventDetailObj = {
      visitor: visitor
    };
    var email;
    if (signup.location == 'email signup') {
      email = $('.adobelaunch__emailsignup').val();
    } else {
      email = $('input[name="welcomeEmailSignUp"') && $('input[name="welcomeEmailSignUp"').val() ? $('input[name="welcomeEmailSignUp"').val() : $('input[name="hpEmailSignUp"').val();
    }
    var url = $('.footer-email-signup').data('encode-url') + '?email=' + email;
    $.ajax({
      url: url,
      type: 'get',
      dataType: 'json',
      success: function (data) {
        if (data) {
          eventDetailObj.visitor.email_address = email;
          eventDetailObj.visitor.MD5_hash_email_address = data.md5;
          eventDetailObj.visitor.SHA256_hash_email_address = data.sha256;
        } else {
          eventDetailObj.visitor.email_address = '';
          eventDetailObj.visitor.MD5_hash_email_address = '';
          eventDetailObj.visitor.SHA256_hash_email_address = '';
        }
      },
      complete: function() {
        dispatchCustomEvent('emailSignup', eventDetailObj);
      }
    });
  });
}

/**
 * Text / SMS Signup
 * @param atmHelper
 * @returns
*/
function textSignup(atmHelper) {
  var sha256 = require('js-sha256');
  atmHelper.textSignup(function (dataObject) {
    var visitor = {};
    var eventDetailObj = {
      visitor: visitor
    };
    let eventObjVisitor = eventDetailObj.visitor;
    eventObjVisitor.SHA256_hash_first_name = sha256(dataObject.firstName);
    eventObjVisitor.SHA256_hash_last_name = sha256(dataObject.lastName);
    eventObjVisitor.SHA256_hash_phone = sha256(dataObject.phoneNumber);
    eventObjVisitor.SHA256_hash_billing_zip = sha256(dataObject.zipCode);
    window.pageData.visitor.SHA256_hash_first_name = sha256(dataObject.firstName);
    window.pageData.visitor.SHA256_hash_last_name = sha256(dataObject.lastName);
    window.pageData.visitor.SHA256_hash_phone = sha256(dataObject.phoneNumber);
    window.pageData.visitor.SHA256_hash_billing_zip = sha256(dataObject.zipCode);
    dispatchCustomEvent('textSignup', eventDetailObj);
  })
}

/**
 * Open Minicart event
 * @param atmHelper
 * @returns
 */
function openMiniCart(atmHelper) {
  atmHelper.openMiniCart(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('openMinibag', detail);
  });
}

/**
 * On Updation of Minicart
 * @returns
 */
function updateMiniCart(atmHelper) {
  atmHelper.updateMiniCart(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('bagQuantityChangeFromMiniBag', detail);
  });
}

/**
 * Fetch the object of product Array
 *
 * @param {*} atmHelper
 */
function productArrayUpdate(atmHelper) {
  atmHelper.productArrayUpdate(function (productArray, products) {
    var detail = {
      product_array: productArray,
      products: products
    };
    dispatchCustomEvent('productArrayUpdate', detail);
  });
}

function toggleModelView(atmHelper) {
  atmHelper.toggleModelView(function (productArray, products) {
    var detail = {
      product_array: productArray,
      products: products
    };
    dispatchCustomEvent('toggleModelView', detail);
  });
}

/**
 * Quick view events
 * @returns
 */
function showQuickView(atmHelper) {
  atmHelper.showQuickView(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('quickLook', detail);
  });
}

/**
 * Add to bag events
 * @returns
 */
function addToBag(atmHelper) {
  atmHelper.addToBag(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('bagAdd', detail);
  });
}

/**
 * select bonus product
 * @returns
 */
function selectbonusProduct(atmHelper) {
  atmHelper.selectbonusProduct(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('bagAdd', detail);
  });
}

/**
 * get bonus product on applied coupon
 * @returns
 */
function getbonusproductonAppliedCoupon(atmHelper) {
  atmHelper.getbonusproductonAppliedCoupon(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('bagAdd', detail);
  });
}

/**
 * Adding to wishlist
 * @param atmHelper
 * @returns
 */
function addToFav(atmHelper) {
  atmHelper.addToFav(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('favoritesAdd', detail);
  });
}

/**
 * Adding to wishlist
 * @param atmHelper
 * @returns
 */
function moveFavToBag(atmHelper) {
  atmHelper.moveFavToBag(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('moveFavoritesToBag', detail);
  });
}

/**
 * Removing From wishlist
 * @param atmHelper
 * @returns
 */
function removeFromFav(atmHelper) {
  atmHelper.removeFromFav(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('favoritesRemove', detail);
  });
}

/**
 * Fetch the object of update quantity
 *
 * @param {*} atmHelper
 */
function udpateCartQuantity(atmHelper) {
  atmHelper.udpateCartQuantity(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('bagQuantityChange', detail);
  });
}

/**
 * On moving Cart item to wishlist
 * @param atmHelper
 * @returns
 */
function cartMoveToFav(atmHelper) {
  atmHelper.cartMoveToFav(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('favoritesAdd', detail);
  });
}

/**
 * On providing coupon code
 * @param atmHelper
 * @returns
 */
function enterPromoCode(atmHelper) {
  atmHelper.enterPromoCode(function (order) {
    var detail = {
      order: order
    };
    dispatchCustomEvent('promoCodeEntered', detail);
  });
}

/**
 * On Exit to payment
 * @param atmHelper
 * @returns
 */
function exitToPaymentService(atmHelper) {
  atmHelper.exitToPaymentService(function (order) {
    var detail = {
      order: order
    };
    dispatchCustomEvent('paymentServiceExit', detail);
  });
}

/**
 * For any form error
 * @param atmHelper
 * @returns
 */
function formError(atmHelper) {
  atmHelper.formError(function (form) {
    var detail = {
      form: form
    };
    dispatchCustomEvent('formError', detail);
  });
}

/**
 * On changing steps from checkout
 * @param atmHelper
 * @returns
 */
function checkoutStepChange(atmHelper) {
  atmHelper.checkoutStepChange(function (page, products, visitor) {
    var detail = {
      page: page,
      products: products,
      visitor: visitor
    };
    dispatchCustomEvent('checkoutStepChange', detail);
  });
}

/**
 * On click of wishlist share
 * @param atmHelper
 * @returns
 */
function wishlistShare(atmHelper) {
  atmHelper.wishlistShare(function () {
    dispatchCustomEvent('wishlistShare', {});
  });
}

/**
 * On click of alternate imgae
 * @param atmHelper
 * @returns
 */
function altImageView(atmHelper) {
  atmHelper.altImageView(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('altImageView', detail);
  });
}

/**
 * On click of alternate imgae
 * @param atmHelper
 * @returns
 */
function quickViewAltImageView(atmHelper) {
  atmHelper.quickViewAltImageView(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('altImageView', detail);
  });
}

/**
 * Start of waitlist
 * @param atmHelper
 * @returns
 */
function waitListStart(atmHelper) {
  atmHelper.waitListStart(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('waitListAddStart', detail);
  });
}

/**
 * Completion of waitlist
 * @param atmHelper
 * @returns
 */
function waitListComplete(atmHelper) {
  atmHelper.waitListComplete(function (products, hashEmail, hashPhone) {
    var detail = {
      products: products,
      visitor: {
        SHA256_hash_email_address: hashEmail,
        SHA256_hash_phone: hashPhone
      }
    };
    dispatchCustomEvent('waitListAddComplete', detail);
  });
}

/**
 * Bopus Start
 * @param atmHelper
 * @returns
 */
function bopusStart(atmHelper) {
  atmHelper.bopusStart(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('bopusStart', detail);
  });
}
/**
 * Bopus Search
 * @param atmHelper
 * @returns
 */
function bopusSearch(atmHelper) {
  atmHelper.bopusSearch(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('bopusSearch', detail);
  });
}

/**
 * Bopus Cart Search
 * @param atmHelper
 * @returns
 */
function bopusCartSearch(atmHelper) {
  atmHelper.bopusCartSearch(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('bopusSearch', detail);
  });
}

/**
 * Bopus Store Select
 * @param atmHelper
 * @returns
 */
function bopusCartSelect(atmHelper) {
  atmHelper.bopusCartSelect(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('bopusSelect', detail);
  });
}

/**
 * Bopus Search
 * @param atmHelper
 * @returns
 */
function findStoreSearch(atmHelper) {
  atmHelper.findStoreSearch(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('findStoreSearch', detail);
  });
}

/**
 * Search redirect
 * @param atmHelper
 * @returns
 */
function searchRedirect(atmHelper) {
  atmHelper.searchRedirect(function (search) {
    var detail = {
      search: search
    };
    dispatchCustomEvent('searchFormSubmit', detail);
  });
}

/**
 * Trigger Event on Cancel Order
 * @param atmHelper
 * @returns
 */
function cancelOrder(atmHelper) {
  atmHelper.cancelOrder(function (orderNo) {
    var detail = {
      order: {
        id: orderNo
      }
    };
    dispatchCustomEvent('cancelOrder', detail);
  });
}

function checkAmexPoints(atmHelper) {
  atmHelper.checkAmexPoints(function () {
    dispatchCustomEvent('checkoutStepChange', pageData);
  });
}

function applyAmexPoints(atmHelper) {
  atmHelper.applyAmexPoints(function () {
    dispatchCustomEvent('checkoutStepChange', pageData);
  });
}

function pdpShopTheLook(atmHelper) {
  atmHelper.pdpShopTheLook(function (products) {
      var detail = {
          products: products
      };
      dispatchCustomEvent('quickLook', detail);
  });
}

function closeWidget(atmHelper) {
  atmHelper.closeWidget(function (pd) {
    if ('name' in pd) {
      window.pageData.page.name = pd.name;
    }
    window.pageData.page.type= pd.type;
    dispatchCustomEvent('pageLoad', pd);
  });
}

/**
 * Trigger Event on Cancel Pre Order
 * @param atmHelper
 * @returns
 */
 function cancelPreOrder(atmHelper) {
  atmHelper.cancelPreOrder(function (orderNo) {
    var detail = {
      order: {
        id: orderNo
      }
    };
    dispatchCustomEvent('cancelPreOrder', detail);
  });
}

/**
 * Trigger Event on errors
 * @param atmHelper
 * @returns
 */
function trackError(atmHelper) {
  atmHelper.trackError(function (products, error) {
    var detail = {
      products: products,
      error: error
    };

    dispatchCustomEvent('error', detail);
  });
}

/**
 * Trigger Event on product click on product arras
 * @param atmHelper
 * @returns
 */
function productClick(atmHelper) {
  atmHelper.productClick(function (productClickData) {
    var detail = {
      products: productClickData,
    };
    dispatchCustomEvent('productClick', detail);
  });
}

function visitorDataPopulated(atmHelper) {
  atmHelper.visitorDataPopulated(function (visitorData) {
    dispatchCustomEvent('visitorDataPopulated', visitorData);
  });
}

/**
 * Completion of call to order
 * @param atmHelper
 * @returns
 */
function callToOrder(atmHelper) {
  atmHelper.callToOrder(function (products) {
    var detail = {
      products: products
    };
    dispatchCustomEvent('callToOrder', detail);
  });
}

 /**
 * Bread Financials card Application status
 * @param atmHelper
 * @returns
 */
function saksCardSubmit(atmHelper) {
  atmHelper.saksCardSubmit(function (saksCardStatus) {
    var detail = {
      card_application: saksCardStatus
    };
    dispatchCustomEvent('saksCardSubmit', detail);
  });
}

module.exports = function (atmHelper) {
  suggestedSearch(atmHelper);
  emailSignupModal(atmHelper);
  textSignup(atmHelper);
  toggleModelView(atmHelper);
  openMiniCart(atmHelper);
  updateMiniCart(atmHelper);
  productArrayUpdate(atmHelper);
  showQuickView(atmHelper);
  addToBag(atmHelper);
  addToFav(atmHelper);
  moveFavToBag(atmHelper);
  udpateCartQuantity(atmHelper);
  removeFromFav(atmHelper);
  cartMoveToFav(atmHelper);
  enterPromoCode(atmHelper);
  exitToPaymentService(atmHelper);
  formError(atmHelper);
  checkoutStepChange(atmHelper);
  wishlistShare(atmHelper);
  altImageView(atmHelper);
  quickViewAltImageView(atmHelper);
  waitListStart(atmHelper);
  waitListComplete(atmHelper);
  bopusStart(atmHelper);
  bopusSearch(atmHelper);
  bopusCartSearch(atmHelper);
  bopusCartSelect(atmHelper);
  findStoreSearch(atmHelper);
  searchRedirect(atmHelper);
  cancelOrder(atmHelper);
  selectbonusProduct(atmHelper);
  getbonusproductonAppliedCoupon(atmHelper);
  checkAmexPoints(atmHelper);
  applyAmexPoints(atmHelper);
  pdpShopTheLook(atmHelper);
  closeWidget(atmHelper);
  cancelPreOrder(atmHelper);
  visitorDataPopulated(atmHelper);
  trackError(atmHelper);
  productClick(atmHelper);
  callToOrder(atmHelper);
  saksCardSubmit(atmHelper);
};
