'use strict';

const utilhelper = require('core/components/utilhelper');
/** Constants */
const $body = $('body');

/**
 * Is the window large-ish?
 * @returns {Boolean} true if large, otherwise false.
 */
function isDesktop() {
  return window.innerWidth >= 1024;
}

function isPrefCenter() {
  return !!document.querySelector('.preference-center-page');
}

function getBannerHeight() {
  const headerBanner = document.querySelector('.header-banner');
  return headerBanner === null ? 0 : headerBanner.clientHeight;
}

/**
 * Adjusts the `top` value on the .page-header,
 * Because the header has a position: sticky, this will showing or hiding it.
 *
 * @param {String} state 'show' or 'hide'
 */
function adjustStickyHeaderHeight(state) {
  const header = document.getElementsByClassName('page-header')[0];
  if (!header) { return; }

  let height;
  if (state === 'show') {
    // When revealing the header on scroll up, we don't want to reveal the banner.
    height = getBannerHeight();
  } else {
    // With position: sticky, this allows the header to normally when you scroll to the top of the page
    // But scroll out of view and stay there until you scroll up.
    height = header.clientHeight;
  }

  header.style.top = '-' + height + 'px';
}

function showStickyHeader() {
  adjustStickyHeaderHeight('show');
}

function allowStickyHeaderToHide() {
  adjustStickyHeaderHeight('hide');
}

function adjustForToolbars() {
  if (!isDesktop()) {
    if ($(window).outerHeight() <= $(window).innerHeight()) {
      $body.addClass('fix-for-toolbars');
    } else {
      $body.removeClass('fix-for-toolbars');
    }
  }
}

function headerBannerCarouselInit() {
  $('#banner-content').slick({
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  });
}

function headerBannerCarouselA11y() {
  const $bannerContentSlickList = $('#banner-content.slick-initialized .slick-list');
  const totalSlides = $bannerContentSlickList.find('.slick-slide').length;
  $bannerContentSlickList.attr('aria-live', 'polite');
  $bannerContentSlickList
    .find('.slick-slide')
    .each(function (i) {
      $(this).attr('aria-label', i + 1 + ' of ' + totalSlides);
    });
}


module.exports = function () {
  /**
   * Homepage Dots dynamic position
   */
  function homepagedots() {
    setTimeout(function () {
      if ($(window).width() <= 1023.99) {
        var h = $('.hero-banner').find('.content-image').height() - 30;
        $('.hero-banner').find('.slick-dots').css('top', h);
      }
    }, 100);
  }

  /**
   * Terms and condition pop up
   */
  function termclose() {
    $('.terms-overlay').remove();
    $('.term-condition-section').addClass('hide');
  }

  $body.on('click touchstart', '.terms-overlay', function () {
    termclose();
  });

  $body.on('click', '.terms-condition', function (e) {
    e.preventDefault();
    $body.append('<div class="terms-overlay"></div>');

    const $this = $(this);

    $('.terms-overlay').addClass('show');
    if ($this.hasClass('bannerModal')) {
      var data = $this.closest('.banner-asset').find('.term-condition-section').clone();
      $('.custom-modal-container.term-condition-section').html($(data).html()).removeClass('hide');
    } else {
      $('.term-condition-section').removeClass('hide');
    }
  });

  $body.on('click', '#terms-condition-close, .term-condition-section .continue-shop', function (e) {
    e.preventDefault();
    termclose();
  });

  /**
   * View Details section Pop up
   */
  function detailsClose() {
    $('.view-details-overlay').remove();
    $body.find('div.view-details-popup-main').remove();
  }

  $body.on('click', '.view-details-home', function (e) {
    e.preventDefault();
    $body.append('<div class="view-details-popup-main"><div class="view-details-overlay"></div></div>');
    $body
      .find('div.view-details-popup-main')
      .append($('.' + $(this).attr('id')).html());
  });

  $body.on('click', '.view-details-popup-main .view-details-overlay', function () {
    detailsClose();
  });

  $body.on('click', '.view-details-popup-main #view-detail-close', function () {
    detailsClose();
  });

  // Listen to the page:scroll events to show and hide the header.

  if (!isPrefCenter()) {
    $(document).on('page:scrollDown', allowStickyHeaderToHide).on('page:scrollUp', showStickyHeader);
  } else {
    $(document).on('page:scrollDown', allowStickyHeaderToHide);
  }

  // Make sure to show the header when a item is added to the cart.
  $('body').on('product:afterAddToCart', showStickyHeader);

  // Set up the header display before a user scrolls.
  allowStickyHeaderToHide();

  // Watch scrolling
  $(window).scroll(function () {
    const footerUtilitySection = $('.footer-top-utility-section');

    if (footerUtilitySection.length) {
      if (window.innerHeight + window.scrollY > footerUtilitySection.offset().top) {
        if (!$('#promo-drawer').hasClass('promo-drawer-open')) {
          $('#drawer-tab').removeClass('ready');
        }
      } else {
        $('#drawer-tab').addClass('ready');
      }
    }
  });
  $(window).resize(function () {
    homepagedots();
    adjustForToolbars();

  });
  if ($('#banner-content').find('.banner-asset').length > 1) {
    headerBannerCarouselInit();
    headerBannerCarouselA11y();
  }
  homepagedots();

  // Add Flex Break for Hero Banner CTAs
  $('.hero-banner .content-text').each(function () {
    const $this = $(this);
    var ctaLen = $this.find('.cta-link').length;
    if (ctaLen === 6) {
      $this.find('.cta-link:nth-child(3)').after("<div class='break'></div>");
    }
    if (ctaLen === 9) {
      $this.find('.cta-link:nth-child(3)').after("<div class='break'></div>");
      $this.find('.cta-link:nth-child(6)').after("<div class='break'></div>");
    }
  });
  // copy the product count from the search API to the tile template
  const $productCountInCat = $('.category-slider .product-count-in-cat');
  if ($productCountInCat.length > 0) {
    $('.category-product-count').html($productCountInCat.attr('attr-product-count'));
  }
};
