var clientSideValidation = require('core/components/clientSideValidation');
var focusEnabled = false;
/**
 * When clicking on the document we are closing the custom select dropdown.
 */
function closeCustomSelect() {
  $('.custom-select.current_item select').trigger('blur');
  $('.custom-select').removeClass('current_item');
  $('.custom-select li').removeClass('hover');
}
/**
 * validate CreditCard form data in the billing - payment section
 */
function validateCreditCardFormData() {
  $('.credit-card-form input:visible').trigger('blur');
  const $savedCreditCvvHolder = $('.savedCreditCvvHolder input:visible');
  if ($savedCreditCvvHolder.length) {
    $savedCreditCvvHolder.trigger('blur');
  }
}

module.exports = {
  inputfocusEvent: function () {
    // Needs the focus event to do the floating label value.
    $('body')
      .off('focus', '.form-group input, .form-group select, .form-group textarea')
      .on('focus', '.form-group input, .form-group select, .form-group textarea', function () {
        var $label = $(this).closest('.form-group').find('label');
        if ($label.length && !$label.hasClass('input-focus')) {
          $label.addClass('input-focus');
        }
      });
    // Needs blur event to set the float the label value as normal position.
    $('body')
      .off('blur', '.form-group input, .form-group select, .form-group textarea')
      .on('blur', '.form-group input, .form-group select, .form-group textarea', function () {
        var $this = $(this);
        var $label = $this.closest('.form-group').find('label');

        // Handled auto-focus issue only in case of IE for address select box CO flow.
        if ($this.hasClass('selected-option') && $this.text().length !== '') {
          return false;
        }
        if ($this.attr('id') === 'registration-form-hudsonbay-rewards' && $this.next('span.hbc-loyalty-number-prefix:visible').length !== 0) {
          return false;
        }
        if ($this.val() !== null) {
          if (!$this.hasClass('do-not-float')) {
            if (!$this.val().length && $label.hasClass('input-focus')) {
              $label.removeClass('input-focus');
            }
          }
        } else if ($label.hasClass('input-focus')) {
          $label.removeClass('input-focus');
        }
      });
  },
  handleEDQCallbackAddress: function () {
    var $edqVerificationSuggestion = $('#edq-verification-suggestion-box');

    if ($edqVerificationSuggestion.length > 0) {
      window.MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
      var observer = new MutationObserver(function (mutation) {
        if (!$edqVerificationSuggestion.is(':visible')) {
          setTimeout(function () {
            $('form.address-form')
              .find('input:visible, select:visible')
              .each(function () {
                if ($(this).val() !== '') {
                  clientSideValidation.validateFormonBlur.call(this);
                }
              });
            window.formFields.adjustForAutofill();
          }, 1500);
        }
      });
      var config = {
        attributes: true
      };

      observer.observe($edqVerificationSuggestion.get()[0], config);
    }
  },
  adjustAddressAutoFill: function () {
    $('body').on('mousedown', '.edq-global-intuitive-address-suggestions div', function () {
      // SFDEV-4665 | Changes for QAS float foxus and shipping blur
      var setFocus = setInterval(changeFocus, 200);

      function changeFocus() {
        if (focusEnabled) {
          clearInterval(setFocus);
          focusEnabled = false;
        } else {
          focusEnabled = window.formFields.adjustForAutofill();
          if ($('body').find('.checkout-primary-section').length) {
            $('form:visible input.shippingAddressOne:visible, form:visible input.billingAddressOne:visible')
              .closest('form')
              .find('input:visible, select:visible')
              .each(function () {
                var $this = $(this);

                if ($this.val() !== '') {
                  clientSideValidation.validateFormonBlur.call(this);
                  if (this.name === 'dwfrm_shipping_shippingAddress_addressFields_postalCode') {
                    $('input[name$="shippingAddress_addressFields_postalCode"]').blur();
                  }
                }
                var result = clientSideValidation.checkFormvalidCheckout.call(this);
                if (result && $this.closest('.checkout-primary-section').length !== 0) {
                  $('.next-step-button:visible').find('button[type="submit"]').removeAttr('disabled');
                }
              });
          } else {
            $('form:visible input#address1:visible')
              .closest('form')
              .find('input:visible, select:visible')
              .each(function () {
                if ($(this).val() !== '') {
                  clientSideValidation.validateFormonBlur.call(this);
                }
              });
          }
          setTimeout(function () {
            focusEnabled = window.formFields.adjustForAutofill();
            validateCreditCardFormData();
          }, 1000);
        }
      }
    });
  },
  selectbox: function () {
    // var $windowMedia = window.matchMedia('(min-width: 1025px)').matches;
    var $windowMedia = true;
    window.formFields.updateSelect();

    $('body')
      .on('click', '.selected-option', function () {
        var $h = 0;
        var $this = $(this);
        var $select = $this.siblings('select');
        var $selectionList = $this.siblings('.selection-list');
        var $customSelect = $this.closest('.custom-select');

        if ($select.is(':disabled')) {
          return false;
        }

        if ($windowMedia) {
          $('.custom-select.current_item select').trigger('blur');
          window.$currentkeycode = '';
          $('.custom-select').not($customSelect).removeClass('current_item');

          if (window.innerWidth < 600) {
            // Mobile fix
            $selectionList.css('top', '5px');
          } else {
            $selectionList.css('top', $this.outerHeight());
          }
          $customSelect.toggleClass('current_item');

          if (!$customSelect.hasClass('current_item')) {
            $select.trigger('blur');
          }

          if ($selectionList.find('li').length > 10) {
            $.each($selectionList.find('li:visible').slice(0, 10), function () {
              $h += $(this).outerHeight();
              $selectionList.height($h - 1);
            });

            var h = 0;
            $.each(
              $this
                .closest('.custom-select')
                .find('.selection-list li:visible')
                .splice(0, $customSelect.find('.selection-list li.selected:visible').index() - 1),
              function () {
                h += $(this).outerHeight();
              }
            );
            $selectionList.scrollTop(h);
          } else {
            $selectionList.height('auto');
          }
        }
        return true;
      })
      .on('click', '.selection-list li', function () {
        if ($windowMedia) {
          var $this = $(this);
          var $item = $this.closest('.custom-select');
          var $option = $item.find('select option');
          var $selectedOption = $item.find('.selected-option');

          if ($option.eq($this.index()).text() === $item.find('.selected-option.selected').text() || $this.is(':first-of-type')) {
            $item.removeClass('current_item');
            return false;
          }

          $item.find('li').removeClass('hover selected');
          $this.addClass('selected');
          $option.eq($this.index()).prop('selected', true);
          $selectedOption.text($this.text());

          $item.removeClass('current_item');
          $item.find('select').trigger('change').trigger('blur');
          if ($selectedOption.text().trim() === '') {
            $item.find('label').removeClass('input-focus');
          } else {
            $item.find('label:not(".input-focus")').addClass('input-focus');
          }
        }
        return true;
      })
      .on('change input', 'select', function () {
        window.formFields.updateSelect();
      });

    $('body').on('click', function (e) {
      if ($windowMedia && !$('.custom-select.current_item').is(e.target) && !$('.custom-select.current_item').find('*').is(e.target)) {
        closeCustomSelect();
      }
    });

    $('body')
      .off('keydown')
      .on('keydown', function (e) {
        var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0;
        var $activeSelect = $('.custom-select.current_item');
        var $mac = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;
        var $customSelect = $('.custom-select');

        if ($activeSelect.length > 0 && $windowMedia) {
          $activeSelect
            .find('select')
            .focus()
            .addClass('current_item');
          var $list = $activeSelect.find('li');
          var $active;
          var $selectedItem = $activeSelect.find('li.selected');

          if (!$selectedItem.length) {
            $activeSelect.find('li').eq($activeSelect.find('option:selected').index()).addClass('selected');
          }

          if (key === 8) {
            // Delete key
            window.$currentkeycode = window.$currentkeycode === undefined ? '' : window.$currentkeycode.slice(0, -1);
          } else {
            window.$currentkeycode += window.$currentkeycode === undefined ? '' : $.trim(String.fromCharCode(key).toLowerCase());
          }
          $active = $activeSelect.find('li[label^="' + window.$currentkeycode + '"]');

          if ($active.length === 0) {
            window.$currentkeycode += window.$currentkeycode === undefined ? '' : $.trim(String.fromCharCode(key));
            window.$currentkeycode = window.$currentkeycode.substr(window.$currentkeycode.length - 1, 1);
            $active = $activeSelect.find('li[label^="' + window.$currentkeycode + '"]');
          }

          if (key === 40) {
            if ($selectedItem.length > 0 && $activeSelect.find('li.hover').length === 0) {
              $selectedItem.addClass('hover');
            }
            $active = $activeSelect.find('li.hover');
            $activeSelect.find('li').removeClass('hover');
            $active.next().addClass('hover');
          } else if (key === 38) {
            if ($selectedItem.length > 0 && $activeSelect.find('li.hover').length === 0) {
              $selectedItem.addClass('hover');
            }
            $active = $activeSelect.find('li.hover');
            if (!$active.prev().hasClass('hide')) {
              $activeSelect.find('li').removeClass('hover');
              $active.prev().addClass('hover');
            }
          } else if (key === 13) {
            if ($active.length === 0 && $activeSelect.find('li.hover').length > 0) {
              $activeSelect.find('li.hover').trigger('click');
            } else {
              $active.eq(0).trigger('click');
              if ($mac) {
                $customSelect.find('select').css({ left: '0px' });
              } else {
                $customSelect.find('select').css({ left: '-9999px' });
              }
            }
          } else if (key === 9 || key === 27) {
            closeCustomSelect();
            if ($mac) {
              $customSelect.find('select').css({ left: '0px' });
            } else {
              $customSelect.find('select').css({ left: '-9999px' });
            }
          } else if ($active.length !== 0) {
            $list.removeClass('hover');
            $active.eq(0).addClass('hover');
          }
          if (key === 8) {
            // Delete key
            return false;
          }

          var h = 0;
          if ($activeSelect.find('.selection-list li.hover').length === 0) {
            $.each(
              $activeSelect.find('.selection-list li:visible').splice(0, $activeSelect.find('.selection-list li.selected:visible').index() - 1),
              function () {
                h += $(this).outerHeight();
              }
            );
          } else {
            $.each($activeSelect.find('.selection-list li:visible').splice(0, $activeSelect.find('.selection-list li.hover:visible').index() - 1), function () {
              h += $(this).outerHeight();
            });
          }

          $activeSelect.find('.selection-list').scrollTop(h);
        }
        return true;
      });
  }
}
