var appyForSaksDiv = $('.js-apply-for-saks.link-cta.direct');
var preApprovalForSaksDiv = $('.js-apply-for-saks.link-cta.pre-approval');
var preApprovalMsg = $('.pre-approval-msg');
var skipAndApplyDiv = $('.js-apply-for-saks.link-cta.skipandapply');

if (window.SitePreferences &&
    !window.SitePreferences.enableSaksFirstPreApprovalToggle &&
    !window.SitePreferences.enableSaksFirstDirectToggle &&
    appyForSaksDiv.length &&
    preApprovalForSaksDiv.length && preApprovalMsg.length && skipAndApplyDiv.length) {
        $('.js-apply-for-saks.link-cta.direct').show();
        $('.js-apply-for-saks.link-cta.pre-approval').hide();
        $('.pre-approval-msg').hide();
        $('.js-apply-for-saks.link-cta.skipandapply').hide();
    }    
if (window.SitePreferences &&
    window.SitePreferences.enableSaksFirstDirectToggle &&
    !window.SitePreferences.enableSaksFirstPreApprovalToggle &&
    appyForSaksDiv.length &&
    preApprovalForSaksDiv.length && preApprovalMsg.length && skipAndApplyDiv.length) {
        $('.js-apply-for-saks.link-cta.direct').show();
        $('.js-apply-for-saks.link-cta.pre-approval').hide();
        $('.pre-approval-msg').hide();
        $('.js-apply-for-saks.link-cta.skipandapply').hide();
    }  

if (window.SitePreferences &&
    window.SitePreferences.enableSaksFirstPreApprovalToggle &&
    !window.SitePreferences.enableSaksFirstDirectToggle &&
    appyForSaksDiv.length &&
    preApprovalForSaksDiv.length && preApprovalMsg.length && skipAndApplyDiv.length) {
        $('.js-apply-for-saks.link-cta.direct').hide();
        $('.js-apply-for-saks.link-cta.pre-approval').show();
        $('.pre-approval-msg').show();
        $('.js-apply-for-saks.link-cta.skipandapply').hide();
    } 

if (window.SitePreferences &&
    window.SitePreferences.enableSaksFirstPreApprovalToggle &&
    window.SitePreferences.enableSaksFirstDirectToggle &&
    appyForSaksDiv.length &&
    preApprovalForSaksDiv.length && preApprovalMsg.length && skipAndApplyDiv.length) {
        $('.js-apply-for-saks.link-cta.pre-approval').show();
        $('.js-apply-for-saks.link-cta.direct').hide();
        $('.js-apply-for-saks.link-cta.skipandapply').show();
        $('.pre-approval-msg').show();
    }

if (window.SitePreferences.breadEnabled === true) {
    $('.capone-content-cta').addClass('d-none');
    $('.bread-content-cta').removeClass('d-none');
} else {
    $('.capone-content-cta').removeClass('d-none');
    $('.bread-content-cta').addClass('d-none');
}
