import 'regenerator-runtime/runtime';

const processInclude = require('base/util');

window.slick = window.Slick = require('slick-carousel/slick/slick');
window.maskFun = require('jquery-mask-plugin/dist/jquery.mask.min');
window.isTouchscreen = require('core/util/isTouchscreen');
window.hbcSlider = require('./hbcSlider');
window.lazyload = require('./lazyLoad');
window.formFields = require('./formFields/formFields');
window.hbcTooltip = require('core/tooltip');
var floatLabel = require('core/floatLabel');
var utilhelper = require('core/components/utilhelper');

$(document).ready(function () {
  processInclude(require('./formFields/formFieldsGlobal'));
  floatLabel.resetFloatLabel();
  window.base = require('./product/baseV2');
  processInclude(require('./util/loginPopup'));
  processInclude(require('./util/saksFirstCreditCard'));
  processInclude(require('./components/menu'));
  processInclude(require('./components/header'));
  processInclude(require('./components/stickyColumnTop'));
  processInclude(require('base/components/cookie'));
  processInclude(require('./components/consentTracking'));
  processInclude(require('./components/commissionCookies'));
  processInclude(require('./components/footer'));
  processInclude(require('./components/miniCart'));
  processInclude(require('core/components/collapsibleItem'));
  processInclude(require('core/components/clientSideValidation'));
  processInclude(require('base/components/toolTip'));
  processInclude(require('./hbcSlider'));
  processInclude(require('core/components/slot'));
  processInclude(require('./product/tileFocusManager'));
  processInclude(require('./wishi/wishiIcon'));
  processInclude(require('./components/welcomeSignUp'));
  //processInclude(require("./components/chanel"));
  processInclude(require('./util/viewportHeightCalculator'));
  window.lazyload.lazyLoadingHandler();
  window.lazyload.lazyLoadImages();
  // Exclude this block for checkout pages
  if(!$("#checkout-main").length) {
    processInclude(require('./searchOverlay'));
  }
  var isSaksPlusenabled = $('.saksPlusEnabled').length;
  if(isSaksPlusenabled) {
    processInclude(require('./login/saksPlus'));
  }

  var atmHelper = require('./atm/atmHelper');
  var adobeTagManger = require('core/atm/atm');
  adobeTagManger(atmHelper);
  processInclude(require('./atm/fetchVisitorData')); // async fetch dynamic visitor data
  processInclude(require('./util/scrollDirection'));
  processInclude(require('./oneTrust/oneTrustHelper'));

  if (window.SitePreferences.dynamicYieldEnabled == true) {
    processInclude(require('./product/dyrecstrip'));
  }

  if (!window.FABuild) {
    processInclude(require('./components/notAllowedToShipToCountryToast'));
  }
});

//Controls forter customer web id displayed in the page footer.
const $forterData = $('#forterData');
const forterWebIdEnabled = $forterData.data('webidenabled');
if (forterWebIdEnabled) {
  $(document).on('ftr:customerWebIdReady', function(e, webId) {
    if($forterData.length){
      const $forterCustomerWebId = $('.forterCustomerWebId');
      if ($forterCustomerWebId.length && webId) {
        const forterWebIdFormatted = webId.match(/.{1,3}/g).join(' ');
        const contentHtml = $forterCustomerWebId[0].innerHTML;
        const contentWithWebId = contentHtml.replace('{{forterCustomerWebId}}', forterWebIdFormatted);
        $forterCustomerWebId[0].innerHTML = contentWithWebId;
        $forterCustomerWebId.removeClass('d-none');
      }
    }
  });
}

if ($('#forterEnabled').length > 0 && $('#forterEnabled').val()) {
  //Triggers by forter for forter token
  document.addEventListener('ftr:tokenReady', function(evt) {
    var token = evt.detail;
    // Retrieve the token to be sent to back-end
    if ($('#forterToken').length > 0) {
      $('#forterToken').val(token);
    }

    var forterTokenCookieVal = utilhelper.getCookie('forterToken');
    //Make the ajax call only for apple pay
    if (window.SitePreferences &&
      (window.SitePreferences.applePayPDPToggle || window.SitePreferences.applePayCartCheckoutToggle) &&
      window.dw &&
      window.dw.applepay &&
      window.ApplePaySession &&
      window.ApplePaySession.canMakePayments() && forterTokenCookieVal == null) {
      $.ajax({
          url: $('#updateFTCURL').val(),
          type: 'post',
          data: $('#forterToken').serialize()
      });
    }
  });
}

require('base/thirdParty/bootstrap');
require('base/components/spinner');

/**
 * Extend jQuery selectors to allow for :focusable
 */
jQuery.expr[':'].focusable = function (el) {
  return jQuery(el).is('a, button, :input, [tabindex]');
};
jQuery.event.special.touchstart = {
  setup: function (_, ns, handle) {
    this.addEventListener('touchstart', handle, { passive: true });
  }
};
jQuery.event.special.touchend = {
  setup: function (_, ns, handle) {
    this.addEventListener('touchend', handle, { passive: true });
  }
};
jQuery.event.special.touchmove = {
  setup: function (_, ns, handle) {
    this.addEventListener('touchmove', handle, { passive: true });
  }
};
