'use strict';
var debounce = require('lodash/debounce');

const PREFER_NATIVE_SCROLL_ON_TOUCHSCREENS = [
  'category-slider',
  'recommendation-products',
  'primary-images-set',
  'primary-images',
  'homepage-cat-slider',
  'wishlist-cart-slider',
  'ctl-images'
];

/**
 * Returns all unique values of an array, based on a provided comparator function.
 * @param {Array} arr An array of objects
 * @param {Function} fn A comparison function
 * @returns {Array} An array of unique elements, as determined by the comparison function
 */
const uniqueElementsBy = (arr, fn) => {
  return arr.reduce((acc, v) => {
    if (!acc.some(x => fn(v, x))) acc.push(v);
    return acc;
  }, []);
};

/**
 * Merges two Slick 'responsive' config objects together
 * @param {Array} target A responsive object in the slick config
 * @param {Array} source A responsive object in the slick config
 */
const mergeBreakpoints = (target, source) => {
  const mergedSources = uniqueElementsBy(source.concat(target), (a, b) => a.breakpoint === b.breakpoint);
  return mergedSources.map(sourceElement => {
    let targetElement = target.find(el => sourceElement.breakpoint === el.breakpoint);
    if (targetElement) {
      return {
        breakpoint: sourceElement.breakpoint,
        settings: Object.assign({}, sourceElement.settings, targetElement.settings)
      };
    }
    return sourceElement;
  });
};

/**
 * Returns a configuration object, based on the defaultSliderConfig, any property of which can be overridden
 * @param {Object} overrides Any overrides to the default slick carousel configuration
 * @return {Object} A slick carousel config object
 */
const getSliderConfig = overrides => {
  const defaultConfigCopy = Object.assign({}, defaultSliderConfig);
  if (!(overrides && typeof overrides === 'object')) {
    return defaultConfigCopy;
  }
  const mergedConfig = Object.assign(defaultConfigCopy, overrides);
  if (!(overrides.responsive && overrides.responsive instanceof Array)) {
    return mergedConfig;
  }
  // If we're here, we need to merge the responsive config
  mergedConfig.responsive = mergeBreakpoints(defaultConfigCopy.responsive, mergedConfig.responsive);
  return mergedConfig;
};

/**
 * The default Slick carousel config object.
 */
const defaultSliderConfig = {
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 150,
  swipe: true,
  draggable: true,
  swipeToSlide: false,
  touchThreshold: 10,
  dots: true,
  arrows: true,
  centerMode: false,
  infinite: false,
  focusOnSelect: false,
  variableWidth: false,
  accessibility: true,
  lazyLoad: 'progressive',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        swipeToSlide: true
      }
    }
  ]
};

/**
 * Configurations for individual slick carousels.
 */
const sliderData = {
  'recommendation-products': getSliderConfig({
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: false,
    responsive: [
      {
        breakpoint: 1260,
        settings: { dots: false, slidesToShow: 4, slidesToScroll: 4 }
      },
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 3.5,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 544,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      }
    ]
  }),
  'wishlist-cart-slider': getSliderConfig({
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: false,
    responsive: [
      {
        breakpoint: 1260,
        settings: { dots: false, slidesToShow: 4, slidesToScroll: 4 }
      },
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 3.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 544,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  }),
  'primary-images': getSliderConfig({
    infinite:true,
    swipeToSlide:true,
    dots:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide:true,
          infinite:true,
        }
      },
    ]
  }),
  'complete-the-look:not("#completeTheLookDrawer")': getSliderConfig({
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 544,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  }),
  'complete-the-look-full-width': getSliderConfig({
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 544,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  }),
  'primary-images-set': getSliderConfig(),
  'quick-view-images': getSliderConfig(),
  'ctl-images': getSliderConfig({
    dots: false
  }),
  'edit-product': getSliderConfig({
    dots: true
  }),
  'category-slider': getSliderConfig({
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: false,
    responsive: [
      {
        breakpoint: 1260,
        settings: { dots: false, slidesToShow: 4, slidesToScroll: 4 }
      },
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 544,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }),
  'homepage-slider': getSliderConfig({
    autoplay: true,
    autoplaySpeed: 4500,
    speed: 1000,
    infinite: true
  }),
  'homepage-cat-slider': getSliderConfig({
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: false,
    responsive: [
      {
        breakpoint: 1260,
        settings: { dots: false, slidesToShow: 4, slidesToScroll: 4 }
      },
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 4.5,
          slidesToScroll: 4,
          swipeToSlide: false,
          touchThreshold: 500
        }
      },
      {
        breakpoint: 544,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 2.5,
          slidesToScroll: 2,
          swipeToSlide: false,
          touchThreshold: 500
        }
      }
    ]
  }),
  'category-refinement-bar': getSliderConfig({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true,
    arrows: false,
    swipeToSlide: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 16,
          slidesToScroll: 16
        }
      }
    ]
  }),
  'two-up-row': getSliderConfig({
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 544,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  })
};

var cartReccommendationSliderInit = function (isResize) {
  $('.cart-page-content .hbc-slider').each(function() {
    var $this = $(this);

    if (($this.hasClass('recommendation-products') || $this.hasClass('homepage-cat-slider')) && $this.hasClass('slick-initialized')) {
      $this.slick('unslick');
    }

    if ($this.hasClass('recommendation-products')) {
      const isSliderInitNeeded = readyToSlider($this.children(), sliderData['recommendation-products']);

      if (isSliderInitNeeded) {
        $this.slick(sliderData['recommendation-products']);
      }
    } else if ($this.hasClass('homepage-cat-slider')) {
      const sliderConfig = {
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
          { breakpoint: 1024, settings: { dots: false, arrows: false, slidesToShow: 3, slidesToScroll: 1 } },
          { breakpoint: 544, settings: { dots: false, arrows: false, slidesToShow: 1, slidesToScroll: 1 } }
        ]
      };

      const isSliderInitNeeded = readyToSlider($this.children(), sliderConfig);

      if (isSliderInitNeeded) {
        $this.slick(sliderConfig);
      }
    }
  });

  // Update on resize and orientation change
  if (!isResize) {
    const debounceCartReccommendationSliderInit = debounce(cartReccommendationSliderInit.bind(null, true), 50);
    window.addEventListener('resize', debounceCartReccommendationSliderInit);
    window.addEventListener('orientationchange', debounceCartReccommendationSliderInit);
  }

  window.lazyload.lazyLoadImages();
};

/**
 * Largescreen?
 * @returns {boolean} True for largescreen, otherwise false.
 */
function isDesktop() {
  return window.innerWidth >= 1024;
}

/**
 * Generates a unique ID.
 * @return {String} a unique ID.
 */
const generateUUID = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));

/**
 * Handles clicks on the dots on touchscreens.
 * @param {Event} evt The event object
 */
const handleTouchscreenDotClick = evt => {
  const targetSlide = document.querySelector(`[data-dot-id="${evt.target.parentElement.id}"]`);
  if (!targetSlide) {
    return;
  }
  const targetSlideContainer = targetSlide.parentElement;

  targetSlideContainer.scrollTo({
    left: targetSlide.offsetLeft,
    behavior: 'smooth'
  });
};

/**
 * Builds dot indiators for touchscreen sliders.
 * @param {Element} slider The slider wrapper element
 */
const buildNativeScrollSliderDots = slider => {
  const list = document.createElement('ul');
  ['touchscreen-slider-dots', 'slick-dots'].forEach(str => list.classList.add(str));
  const slides = Array.from(slider.children);
  if (slides && slides.length > 1) {
    slides.forEach(slide => (slide.dataset.dotId = `touchscreen-slider-dot-${generateUUID()}`));
    const dots = slides.map((slide, index) => `<li id="${slide.dataset.dotId}"><button type="button">${index + 1}</button></li>`).join('');
    list.innerHTML = dots;
    list.addEventListener('click', handleTouchscreenDotClick);
    slider.insertAdjacentElement('afterend', list);
  }
};

/**
 * Sets up an intersection observer to update the active dot state on touchscreens.
 * @param {Element} slider A slider container element
 */
const observeNativeScrollSlides = slider => {
  const handleIntersect = entries => {
    entries.forEach(entry => {
      const dot = document.getElementById(entry.target.dataset.dotId);
      if (!dot) {
        return;
      }
      if (entry.isIntersecting) {
        dot.classList.add('slick-active');
        if (
          entry.intersectionRatio >= 0.99 &&
          entry.intersectionRatio <= 0.998 &&
          $(entry.target).is('.primary-image') &&
          dot.classList.contains('slick-active')
        ) {
          $('body').trigger('adobeTagManager:altImageView');
        }
      } else {
        dot.classList.remove('slick-active');
      }
    });
  };
  const observer = new IntersectionObserver(handleIntersect, {
    root: slider,
    rootMargin: '0px',
    threshold: [0.5, 0.99]
  });
  const slides = Array.from(slider.children);
  slides.forEach(slide => observer.observe(slide));
};

/**
 * Sets up touchscreen slider functionality.
 * @param {String} sliderType The key for the type of slider.
 */
const decorateNativeScrollSlider = sliderType => {
  let sliders;
  if (sliderType) {
    sliders = document.querySelectorAll(`.${sliderType}`);
  }
  if (!sliders.length) {
    return;
  }
  sliders.forEach(slider => {
    if (slider.classList.contains('touchscreen-slider-initialized')) {
      return;
    }
    if (slider.dataset.touchscreenSliderDots !== 'false') {
      buildNativeScrollSliderDots(slider);
      observeNativeScrollSlides(slider);
    }
    slider.classList.add('touchscreen-slider-initialized');

    //to center recommendation slider items on mobile and tablet
    var recSec = $('.recommendation-products');
    var ismobile = window.innerWidth < 545;
    var istablet = window.innerWidth < 1025;
    var childrenLengthThreshold = ismobile ? 2 : (istablet ? 3 : 0);

    $.each(recSec, function () {
      var $this = $(this);
      if ($this.children().length <= childrenLengthThreshold) {
        $this.css("justify-content", "center");
      }
    });
  });
};

const initResponsiveSlider = ($slider, sliderType) => {
  const determineResponsiveSliderStatus = function () {
    if (isDesktop()) {
      if ($slider.hasClass('slick-initialized')) {
        $slider.slick('unslick');
      }
      return;
    }
    if (!$slider.hasClass('slick-initialized')) {
      const isSliderInitNeeded = readyToSlider($slider.children(), sliderData[sliderType]);

      if (isSliderInitNeeded) {
        $slider.slick(sliderData[sliderType]);
      }
    }
  };

  // Make sure no other call try to incorrectly intialize slick
  const desktopInitializationObserver = new MutationObserver(function () {
    if ($slider.hasClass('slick-initialized') && (isDesktop() || (PREFER_NATIVE_SCROLL_ON_TOUCHSCREENS.includes(sliderType) && window.isTouchscreen()))) {
      $slider.slick('unslick');
    }
  });
  desktopInitializationObserver.observe($slider.get(0), { attributes: true });

  // Update on resize and orientation change
  const debounceDetermineResponsiveSliderStatus = debounce(determineResponsiveSliderStatus, 50);
  window.addEventListener('resize', debounceDetermineResponsiveSliderStatus);
  window.addEventListener('orientationchange', debounceDetermineResponsiveSliderStatus);

  // Set it up
  determineResponsiveSliderStatus();
};

function readyToSlider(items, sliderConfig) {
  const viewportWidth = window.innerWidth;
  const maxResponsive = sliderConfig.responsive && sliderConfig.responsive[0];
  let shouldInit = false;
  let slidesToShow = 0;

  if (maxResponsive && maxResponsive.breakpoint && viewportWidth > maxResponsive.breakpoint){
      shouldInit = sliderConfig.slidesToShow < items.length;
      slidesToShow = sliderConfig.slidesToShow;
  } else {
      for (let i = 0; i < sliderConfig.responsive.length; i++){
          if (viewportWidth <= sliderConfig.responsive[i].breakpoint){
              shouldInit = sliderConfig.responsive[i].settings.slidesToShow < items.length;
              slidesToShow = sliderConfig.responsive[i].settings.slidesToShow;
          }
      }
  }

  if (!slidesToShow) {
    shouldInit = sliderConfig.slidesToShow < items.length;
    slidesToShow = sliderConfig.slidesToShow;
  }

  return shouldInit;
}

function hbcSliderInit(sliderType, slider, isResize) {
  let $sliders;
  if (sliderType && PREFER_NATIVE_SCROLL_ON_TOUCHSCREENS.includes(sliderType) && window.isTouchscreen() && window.innerWidth < 1024) {
    if(sliderType === 'primary-images'){
      $sliders = $('.' + sliderType + ':not(.slick-initialized)');
      $sliders.slick(sliderData[sliderType]);
      window.lazyload.lazyLoadImages();

      return;
    }

    decorateNativeScrollSlider(sliderType);
    window.lazyload.lazyLoadImages();

    return;
  }
  if (typeof sliderType === 'undefined') {
    $.each(sliderData, function (sliderTypeVal, sliderDataVal) {
      if (PREFER_NATIVE_SCROLL_ON_TOUCHSCREENS.includes(sliderTypeVal) && window.isTouchscreen() && window.innerWidth < 1024) {
        if(sliderTypeVal === 'primary-images' || sliderTypeVal === 'primary-images-set'){
          return;
        }
        decorateNativeScrollSlider(sliderTypeVal);
        return;
      }
      $sliders = $('.' + sliderTypeVal + ':not(.slick-initialized)');

      if (!$sliders.is('.async-slider')) {
        const isSliderInitNeeded = readyToSlider($sliders.children(), sliderDataVal);

        if (isSliderInitNeeded) {
          $sliders.slick(sliderDataVal);
        }
      }
    });
  } else {
    if (typeof slider === 'undefined') {
      $sliders = $('.' + sliderType + ':not(.slick-initialized)');
    } else {
      $sliders = slider;
    }

    $sliders.each(function () {
      const $this = $(this);

      if ($this.hasClass('responsive-slider') || (window.isTouchscreen() && sliderType == 'primary-images-set')) {
        if (!isResize) {
          initResponsiveSlider($this, sliderType);
        }
      } else {
        const isSliderInitNeeded = readyToSlider($this.children(), sliderData[sliderType]);

        if (isSliderInitNeeded) {
          $this.slick(sliderData[sliderType]);
        }
      }
    });
  }

  // Update on resize and orientation change
  if (!isResize) {
    const debounceHbcSliderInit = debounce(hbcSliderInit.bind(null, sliderType, slider, true), 50);
    window.addEventListener('resize', debounceHbcSliderInit);
    window.addEventListener('orientationchange', debounceHbcSliderInit);
  }

  if (sliderType === 'recommendation-products') {
    $('body').trigger('product:recommendationSliderAdded');
  }

  window.lazyload.lazyLoadImages();
};

module.exports = {
  hbcSliderInit: hbcSliderInit,
  cartReccommendationSliderInit: cartReccommendationSliderInit
};
