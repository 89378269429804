'use strict';

var base = {};
var pwdMatchCrit = false;

function isValidPwd(password) {
  var regex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#_?!@$%^()+=><~`}{|&*-])^[^'<>/]{8,}$/);
  return regex.test($(password).val());
}

// handle the close button in the success signup dialog for saksplus
base.closeSignupSuccessMessage = function () {
  $('body').on('click', '#success-signup-messsage-close, .modal-backdrop', function () {
    $('#successSignupMessage').modal('hide');
  });
}

base.validatePasswordCondition = function () {
  $('body').on('focus', '.saks-plus-register #registration-form-password', function () {
    pwdMatchCrit = isValidPwd(this);
  });

  $('body').on('keyup', '.saks-plus-register #registration-form-password', function () {
    var upperLetterRegex = new RegExp(/^(?=.*?[A-Z])/);
    var smallLetterRegex = new RegExp(/^(?=.*?[a-z])/);
    var numberRegex = new RegExp(/^(?=.*?[0-9])/);
    var symbolRegex = new RegExp(/^(?=.*?[#_?!@$%^()+=><~`}{|&*-])^[^'<>/]/);
    var $this = $(this);
    var $passwordCondition = $('.saks-plus-password-condition');

    if ($this.val() !== '') {
      $('.invalid-feedback.pwd-error').addClass('d-none');
      if (!pwdMatchCrit || (pwdMatchCrit && !isValidPwd(this))) {
        if (pwdMatchCrit) {
          pwdMatchCrit = false;
        }
        $passwordCondition.removeClass('d-none').addClass('d-flex');

        // validate the password field if it has 8 characters or more
        $passwordCondition.find('.pwd-condition-1').toggleClass('pass', $this.val().trim().length >= 8);

        // validate if the password has an uppercase letter
        $passwordCondition.find('.pwd-condition-2').toggleClass('pass', upperLetterRegex.test($this.val()));

        // validate if the password has a lowercase letter
        $passwordCondition.find('.pwd-condition-3').toggleClass('pass', smallLetterRegex.test($this.val()));

        // validate if the password has a number
        $passwordCondition.find('.pwd-condition-4').toggleClass('pass', numberRegex.test($this.val()));

        // validate if the password has a symbol
        $passwordCondition.find('.pwd-condition-5').toggleClass('pass', symbolRegex.test($this.val()));
      }
    } else {
      $passwordCondition.removeClass('pass');
      $passwordCondition.find('.pwd-condition-1').removeClass('pass');
      $passwordCondition.find('.pwd-condition-2').removeClass('pass');
      $passwordCondition.find('.pwd-condition-3').removeClass('pass');
      $passwordCondition.find('.pwd-condition-4').removeClass('pass');
      $passwordCondition.find('.pwd-condition-5').removeClass('pass');
    }
  });
}

base.validatePasswordOnBlur = function () {
  $('.saks-plus-register-page #registration-form-password').on('blur', function () {
    var regex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#_?!@$%^()+=><~`}{|&*-])^[^'<>/]{8,}$/);
    var $passwordCondition = $('.saks-plus-password-condition');

    if ($(this).val() !== '') {
      if (regex.test($(this).val()) && !$passwordCondition.hasClass('d-none')) {
        $passwordCondition.removeClass('d-flex').addClass('d-none');
      }
    } else {
      $passwordCondition.removeClass('d-flex').addClass('d-none');
      $('.invalid-feedback.pwd-error').removeClass('d-none');
    }
  });
}

module.exports = base;
