'use strict';
module.exports = function () {
  var sizes = ['xs', 'sm', 'md', 'lg', 'xl'];

  $('.menu-main-item').on('click', function (e) {
    $(this).toggleClass('active');
    $('.account-mainmenu').toggleClass('active');
  });

  /**
   *
   * @param {Element|jQuery} el The element that triggers the group toggle. Can be the title button or the group container
   * @param {Boolean} open Whether to open or close the group.
   */
  function toggleFilterGroup(el, open) {
    var $el = $(el);
    var $group;
    var $trigger;
    if ($el.is('[class*="collapsible-"]')) {
      $group = $el;
      $trigger = $group.find('.btn:first');
    } else {
      $trigger = $el;
      $group = $trigger.parents('[class*="collapsible-"]');
    }

    if (open) {
      $group.addClass('active');
      $trigger.attr('aria-expanded', true);
    } else {
      $group.removeClass('active');
      $trigger.attr('aria-expanded', false);
    }
  }

  /**
   * Opens a filter group
   * @param {Element|jQuery} el The element that triggers a filter group to open. Can be the title button or the group container
   */
  function openFilterGroup(el) {
    toggleFilterGroup(el, true);
  }

  /**
   * Closes a filter group
   * @param {Element|jQuery} el The element that triggers a filter group to close. Can be the title button or the group container
   */
  function closeFilterGroup(el) {
    toggleFilterGroup(el, false);
  }

  /**
   * Closes all filter group
   * @param {jQuery} $group The group that initiated. This function looks for open siblings of that group and closes them.
   */
  function closeAllFilterGroups($group) {
    $group.siblings('.active').each(function (_, group) {
      closeFilterGroup(group);
    });
  }

  var selectors = sizes.map(function (size) {
    return '.collapsible-' + size + ' .btn[aria-controls]';
  });

  $('body').on('click', selectors.join(','), function (evt) {
    evt.preventDefault();
    var $btn = $(evt.currentTarget);
    if ($btn.attr('aria-expanded') === 'true') {
      closeFilterGroup($btn);
    } else {
      if ($btn.data('closeSiblings')) {
        closeAllFilterGroups($btn.parents('[class*="collapsible-"]'));
      }
      openFilterGroup($btn);
    }
  });

  // Open the first refinement filter group
  $('.refinements .collapsible-xl .btn[aria-controls]').first().trigger('click');
};
