'use strict';

var scrollAnimate = require('base/components/scrollAnimate');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
  $.spinner().stop();
  const $homeEmailSignup = $('.home-email-signup');
  const $emailSignupInput = $('.email-signup-input');
  const $input = $emailSignupInput.find('input');
  $homeEmailSignup.find('.error-container .error').remove();
  $emailSignupInput.removeClass('error');

  if ($('.email-signup-message-footer').length === 0) {
    $emailSignupInput.append('<div class="email-signup-message-footer"></div>');
  }
  if (data.success) {
    $('.email-signup-message-footer').append(
      '<div class="d-flex align-items-center alert-success"><span class="success-content-ave">' +
        data.msg +
        '<span class="svg-21-avenue-thick-success-dims svg-21-avenue-thick-success success-img"></span></span></div>'
    );
    $emailSignupInput.addClass('success');
    $input.next('span').remove();
    button.closest('.signup-wrap').find('input,label,button').addClass('invisible');
    button.closest('form').find('.sign-up-bottom').addClass('invisible');
    setTimeout(function () {
      $('.email-signup-message-footer').remove();
      $emailSignupInput.removeClass('success');
      $input.val(''); // remove the email prefill as he has already submitted
      $input.next('label').removeClass('input-focus');
      button.closest('.signup-wrap').find('input,label,button').removeClass('invisible');
      button.closest('form').find('.sign-up-bottom').removeClass('invisible');
      button.removeAttr('disabled');
    }, 3000);
  } else {
    $homeEmailSignup
      .find('.error-container')
      .append('<span class="error">' + data.msg + '</span>');
    if ($input.next('span').length === 0) {
      $('<span class="invalid"></span>').insertAfter($input);
    }
    $emailSignupInput.addClass('error');
    button.removeAttr('disabled');
  }
}

function welcomeToastNitification (element, message) {
  let errorHtml =
    '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
    'fade show" role="alert">' +
    '<button type="button" class="close welcome-toast" data-dismiss="alert" aria-label="Close">' +
    '</button>' +
    '<span class="error-content">'+ message +'</span>'+
    '</div>';
  $(element).html(errorHtml);
  $(element).addClass('show');
  setTimeout(() => {
    $(element).removeClass('show');
  }, 6000);
  $(element).find('button.welcome-toast').on('click', function () {
    $(element).removeClass('show');
  });
}

module.exports = function () {
  const $body = $('body');
  $(window).scroll(function () {
    if ($(window).scrollTop() > 100 && ($('.grecaptcha-badge').css('visibility') == 'hidden' || $('.grecaptcha-badge:visible').length <= 0)) {
      $('.back-to-top').addClass('active');
    } else $('.back-to-top').removeClass('active');
  });

  $('.back-to-top').click(function () {
    scrollAnimate();
  });

  $('.subscribe-email').on('click', function (e) {
    e.preventDefault();
    const $button = $(this);
    const url = $button.data('href');
    const emailId = $('input[name=hpEmailSignUp]').val();
    const reg = /^[\w.%+\-]+@(?!.*\.\.)[\w.\-]+\.[\w]{2,6}$/;
    if (!reg.test(emailId)) {
      return false;
    }
    $.spinner().start();
    $button.attr('disabled', true);
    $.ajax({
      url: url,
      type: 'post',
      dataType: 'json',
      data: {
        emailId: emailId
      },
      success: function (data) {
        displayMessage(data, $button);
        if (data.success) {
          $body.trigger('adobeTagManager:emailsignupmodal', 'footer');
        }
      },
      error: function (err) {
        displayMessage(err, $button);
      }
    });
  });

  $('.signup-wrap').on('input', '.email-signup-input input', function () {
    const $this = $(this);

    if ($this.val() == 0 && $this.parent().hasClass('error')) {
      $this.next('span').remove();
      $this.closest('.email-input-box').find('.error-container span').empty();
      $this.parent().removeClass('error');
    }
  });

  $('.liveChat-btn').on('click', function () {
    var isInternalUser = $('#isInternalUser').data('internaluser');
    // Make sure that User is external using SFCC Geolaction along with Salesfloor Geolocation
    if (!sessionStorage.sf_within_geo && !isInternalUser) {
      $('.arrow-left').toggleClass('d-sm-block');
      $('.live-chat-view-3').removeClass('d-none');
      $('.live-chat-view-2').addClass('d-none');
      $('.chat-img').toggleClass('d-none');
      $('.chat-img-cross').toggleClass('d-none');
    } else {
      $('.live-chat-view-2').removeClass('d-none');
      $('.live-chat-view-3').addClass('d-none');
      $('.chat-img').toggleClass('d-none');
      $('.chat-img-cross').toggleClass('d-none');
    }

    $body.toggleClass('chat-overlay');
    $('.live-chat-content').toggleClass('d-none');

    if (!$('.chat-img-cross').hasClass('d-none')) {
      // Make sure if user is internal,should see the connect with stylist.
      if (sessionStorage.sf_within_geo || isInternalUser) {
        $('.live-chat-view-2').removeClass('d-none');
        $('.live-chat-view-3').addClass('d-none');
        $('.live-chat-view-4').addClass('d-none');
      }
    }
    if ($('.embeddedServiceHelpButton .helpButton .uiButton.helpButtonDisabled').length > 0) {
      $('.chat-service-links .customer-service-chat-link').addClass('live-chat-offline');
    } else if ($('.embeddedServiceHelpButton .helpButton .uiButton.helpButtonEnabled').length > 0) {
      $('.chat-service-links .customer-service-chat-link').addClass('live-chat-online');
    }
  });



  $('.stylist-chat-btn').on('click', function () {
    $('.live-chat-content').toggleClass('d-none');
    $('.live-chat-view-2').toggleClass('d-none');
    $('.chat-img').toggleClass('d-none');
    $('.chat-img-cross').toggleClass('d-none');
  });

  $('.customer-service-button').on('click', function () {
    $('.live-chat-view-2').toggleClass('d-none');
    $('.live-chat-view-3').toggleClass('d-none');
  });

  $('.customer-service-call-link-saks').on('click', function () {
    $('.live-chat-view-2').toggleClass('d-none');
    $('.live-chat-view-3').toggleClass('d-none');
  });

  $('.call-customer-service-saks').on('click', function () {
    $('.live-chat-view-2').toggleClass('d-none');
    $('.live-chat-view-3').toggleClass('d-none');
  });

  $('.chat-img-cross-black').on('click', function () {
    $body.toggleClass('chat-overlay');
    $('.chat-img-cross').addClass('d-none');
    $('.chat-img').removeClass('d-none');
    $('.live-chat-content').addClass('d-none');
    $('.live-chat-view-2').addClass('d-none');
    $('.live-chat-view-3').addClass('d-none');
    $('.wishi-options-content').addClass('d-none');
    $('.wishi-options-view-2').addClass('d-none');
  });

  const $chatIconBtn = $('.wishi-icon-btn');
  const $chatImage = $chatIconBtn.find('.chat-img');

  function chatIconStateToggle() {
    $('.wishi-options-content').addClass('d-none');
    if($chatImage.hasClass('d-none')) {
      $chatImage.removeClass('d-none');
      $chatIconBtn.find('.chat-img-cross').addClass('d-none');
    }
    $body.removeClass('chat-overlay');
  }

  function custSupportBtnFunctionality() {
    if($('.embeddedServiceSidebar').length){
      chatIconStateToggle();
      if($('.sidebarMinimized').length) {
        $('.minimizedContainer').trigger('click');
      }
    }
  }
  
  function hideChatBubble() {
    var pageType = $('.page').attr('data-action');
    if (pageType == 'Cart-Show' || pageType == 'Order-Confirm' || pageType == 'Checkout-Begin') {
      $('.wishi-options-wrapper').addClass('d-none');
    }
  }

  function destroyCookies() {
    chatIconStateToggle();
    sessionStorage.removeItem('isChatEnabled');
    $('.wishi-options-wrapper').removeClass('optionContainer');
    $('.wishi-options-content').removeClass('hideOptions');
    localStorage.removeItem("isChatInitiated");
    hideChatBubble();
  }

  $(document).ready(function () {
    if(sessionStorage.getItem('isChatEnabled')) {
        $chatIconBtn.trigger('click');
        $('.wishi-options-content').addClass('hideOptions');
        $('.wishi-options-wrapper').addClass('optionContainer');
     }
    if($('.account-created').length > 0) {
      welcomeToastNitification($('.acconut-created-welcome-toast'), $('.account-created').val());
    }
  });

  function onChatEndedByAgent(){
    localStorage.removeItem("isChatInitiated");
    hideChatBubble();
  }
  
  if (localStorage.getItem('isChatInitiated')) {
    if ($('.wishi-options-wrapper').hasClass('d-none')) {
      $('.wishi-options-wrapper').removeClass('d-none');
    }
  }
  

  $chatIconBtn.on('click', custSupportBtnFunctionality);
  document.body.addEventListener('init:svcEvents', () => {

    embedded_svc.addEventHandler("onChatEstablished", function(data) {
      localStorage.setItem('isChatInitiated', true);
    });

    embedded_svc.addEventHandler("onAvailability", function() {
      if(sessionStorage.getItem('isChatEnabled')) {
        $('.wishi-options-content').addClass('hideOptions');
        if($('.showDockableContainer').length) {
          $('.wishi-options-wrapper').removeClass('optionContainer');
        }

      }
    });

    embedded_svc.addEventHandler("afterMinimize", chatIconStateToggle);
    embedded_svc.addEventHandler("afterMaximize", function() {
      $body.addClass('chat-overlay');
    });

    embedded_svc.addEventHandler("afterDestroy", destroyCookies);
    embedded_svc.addEventHandler("onChatEndedByChasitor", destroyCookies);
    embedded_svc.addEventHandler("onConnectionError", destroyCookies);
    embedded_svc.addEventHandler("onChatEndedByAgent", onChatEndedByAgent);

  });

  $body.on('click', '.customer-service-chat-link.live-chat-online', function () {
    const $helpButtonEnabled = $('.embeddedServiceHelpButton .helpButton .uiButton.helpButtonEnabled');
    if ($helpButtonEnabled.length > 0) {
      $('.wishi-options-content').toggleClass('d-none');
      $('.live-chat-view-3').toggleClass('d-none');
      $('.chat-img').toggleClass('d-none');
      $('.chat-img-cross').toggleClass('d-none');
      $helpButtonEnabled.trigger('click');
    }
    sessionStorage.setItem('isChatEnabled',true);
  });

  $body.on('click', '.chat-now-link', function () {
    const $helpButtonEnabled = $('.embeddedServiceHelpButton .helpButton .uiButton.helpButtonEnabled');
    if ($helpButtonEnabled.length > 0) {
      $helpButtonEnabled.trigger('click');
    }
    const $minimizedContent = $('.minimizedContainer');
    if($minimizedContent.length > 0) {
      $minimizedContent.trigger('click');
      chatIconStateToggle();
    }
  });

  $('#sf-contextual-widget-landing-page').prop( "disabled", false );

};
