'use strict';

var focusEnabled = false;

/**
 * Needed because there was the autofill suggestion occurs.
 */
function adjustForAutofill() {
  $('.form-group:visible').each(function () {
    const $self = $(this);
    let $input = $self.find('input');
    let inputLength;
    const $inputLabel = $self.find('label');
    const $select = $self.find('select');

    if ($select.length) {
      const $selectedOptions = $self.find('.selected-option');
      if ($selectedOptions.length > 0) {
        $input = $selectedOptions;
        inputLength = $input.text().trim();
      } else {
        $input = $select.find('option:selected');
        inputLength = $input.val();
      }
    } else if ($input.length) {
      inputLength = $input.val();
    } else {
      $input = $self.find('textarea');
      inputLength = $input.val();
    }

    if (inputLength && inputLength.length && !$inputLabel.hasClass('input-focus')) {
      $inputLabel.addClass('input-focus');
    }

    if (
      ($input.hasClass('shippingZipCode') || $input.hasClass('billingZipCode') || $input.hasClass('addressFromZipcode')) &&
      $inputLabel.hasClass('input-focus')
    ) {
      focusEnabled = true;
    }
  });

  return focusEnabled;
}

function quantityHoverBorder() {
  $('body')
    .on('focus mouseover', '.quantity-field .btn-number', function () {
      var $this = $(this);
      var $inputNumber = $this.closest('.quantity-field').find('.input-number');

      if ($this.attr('disabled') !== 'disabled') {
        $inputNumber.addClass('hover-border');

        if (window.innerWidth > 1023 && !window.isTouchscreen()) {
          $this.addClass('desktop-hover-border');
          $inputNumber.addClass('desktop-hover-border');
        }
      }
    })
    .on('focusout mouseout', '.quantity-field .btn-number', function () {
      var $this = $(this);

      $this.removeClass('desktop-hover-border');
      $this.closest('.quantity-field').find('.input-number').removeClass('hover-border desktop-hover-border');
    });
}

function findInsiders($element) {
  if (typeof $element === 'undefined') {
    return;
  }

  var tabbable = $element.find('select, input, textarea, button, a').filter(':visible');

  var firstTabbable = tabbable.first();
  var lastTabbable = tabbable.last();
  /*set focus on first input*/
  firstTabbable.focus();

  /*redirect last tab to first input*/
  lastTabbable.on('keydown', function (e) {
    if (e.which === 9 && !e.shiftKey) {
      e.preventDefault();
      firstTabbable.focus();
    }
  });

  /*redirect first shift+tab to last input*/
  firstTabbable.on('keydown', function (e) {
    if (e.which === 9 && e.shiftKey) {
      e.preventDefault();
      lastTabbable.focus();
    }
  });
}

/**
 * Generate selection list items
 */
function generateSelectionList($element) {
  var $selectionList = $element.find('.selection-list');

  if ($selectionList.length === 0) {
    var listHtml = '';

    if ($element.closest('#quickViewModal').length || $element.closest('#editProductModal').length) {
      $element.append('<div class="selectListContainer"><ul class="selection-list"></ul></div>');
    } else {
      $element.append('<ul class="selection-list"></ul>');
    }

    $element.find('option').each(function () {
      var $option = $(this);
      var listClass = '';
      var styleAttr = '';

      // Set class name
      if ($option.attr('class')) {
        listClass = $option.attr('class');
      }
      if ($option.attr('disabled') === 'disabled') {
        listClass = 'option-disabled';
      }

      // Set background
      if ($option.data('background-color')) {
        styleAttr = 'style="background-color:' + $option.data('background-color') + '"';
      } else if ($option.data('background-image')) {
        styleAttr = 'style="background-image: url(' + $option.data('background-image') + ')"';
      }

      // Generate select items list
      if ($option.closest('.custom-select').hasClass('custom-color-dropdown')) {
        if (!styleAttr) {
          listHtml += `
              <li label="${$option.text().toLocaleLowerCase()}" class="${listClass}">
                <div class="item" style="text-align:center;">
                  <div class="inner-item"></div>
                  ${$option.text()}
                </div>
              </li>`;
        } else {
          listHtml += `
              <li label="${$option.text().toLocaleLowerCase()}" class="${listClass}">
                <div class="item">
                  <div class="inner-item"></div>
                  <span class="swatch-color-holder swatch-circle-${$option.text().toLocaleLowerCase().trim().replace(/\s+/g, '-')}" ${styleAttr}></span>
                  ${$option.text().toLocaleLowerCase()}
                </div>
              </li>`;
        }
      } else {
        listHtml += `<li label="${$option.text().toLocaleLowerCase()}" class="${listClass}">${$option.text()}</li>`;
      }
    });

    $element.find('.selection-list').append(listHtml);
  } else {
    $element.find('.selection-list').find('li').each(function () {
      $element.attr('label', $.trim($(this).text().toLocaleLowerCase()));
    });
  }
}

/**
 * Update selected option
 */
function updateSelectedOption($element) {
  var styleAttr = '';
  var $selectedOption = $element.find('select option:selected');

  if ($element.hasClass('custom-color-dropdown')) {
    // Set background
    if ($selectedOption.data('background-color')) {
      styleAttr = 'style="background-color:' + $selectedOption.data('background-color') + '"';
    } else if ($selectedOption.data('background-image')) {
      styleAttr = 'style="background-image: url(' + $selectedOption.data('background-image') + ')"';
    }

    // Add selected option
    if (!styleAttr) {
      $element.find('.selected-option').html('<div class="option-name">' + $selectedOption.text() + '</div>');
    } else {
      $element
        .find('.selected-option')
        .html(`<span class="swatch-color-holder swatch-circle-${$selectedOption.text().toLocaleLowerCase().trim()}" ${styleAttr}></span>
               <div class="option-name">${$selectedOption.text().toLocaleLowerCase()}</div>`);
    }
  } else {
    $element.find('.selected-option').text($selectedOption.text());
  }
}

/**
 * Converting the default select dropdown to unorder list HTML structure.
 */
function updateSelect() {
  $('.custom-select').each(function () {
    var $this = $(this);
    var $selectionListItems = $this.find('.selection-list li');
    var $selectedOptionElement = $(this).find('select option:selected');

    if ($this.find('.selected-option').length === 0) {
      $this.append('<div class="selected-option"></div>');
    }

    generateSelectionList($this);
    updateSelectedOption($this);

    $selectionListItems
      .removeClass('selected hover')
      .eq($selectedOptionElement.index())
      .addClass('selected');

    var $selectedOption = $this.find('.selected-option');
    $selectedOption.toggleClass('selected', $selectedOptionElement.val() !== '');

    if ($selectionListItems.find('.bold').length > 0) {
      $selectedOption.html($selectionListItems.eq($selectedOptionElement.index()).html());
    }

    $this
      .find('li')
      .removeClass('selected')
      .eq($selectedOptionElement.index())
      .addClass('selected');
    $this.find('.invalid-feedback').appendTo(this);
  });
}

module.exports = {
  updateSelect: updateSelect,
  adjustForAutofill: adjustForAutofill,
  quantityHoverBorder: quantityHoverBorder,
  findInsiders: findInsiders
};
