'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var cookieUtil = require('core/components/utilhelper');
var pageScrollManager = require('../util/pageScrollManager');
var persistentWishlist = require('core/product/persistentWishlist');

const DROPDOWN_NAV_ITEMS_SELECTOR = '.navbar-nav > .nav-item.dropdown:not(.disabled)';
const FOCUSABLE_MENU_ITEM_SELECTOR = '.main-menu .nav-link:focusable, .main-menu .dropdown-link:focusable, .close-menu .close-button';
const LEVEL_1_DROPDOWN_SELECTOR = '.navbar-nav > .dropdown > .nav-link';
const LEVEL_2_NAV_CLASS = 'l2-nav';
const MIN_DESKTOP_WIDTH = 1024;
var level2NavHeader;
var level2BackButton;
var activeNavItem;
var hoverDelay = 250; // ms
var enterTimeout = null;
var exitTimeout = null;
var previousWindowWidth = window.innerWidth;
var inputFocused = false;
const remoteNavEnabled = $('input#remoteNavEnabled') && $('input#remoteNavEnabled').val() === 'true';
const $body = $('body');
const $html = $('html');

function addMenuOverlay() {
  $('.modal-background').addClass('background').css('top', 0);
}

function removeMenuOverlay() {
  $('.modal-background').removeClass('background').removeAttr('style');
}

/**
 * Opens the small screen menu
 * @param {*} e The Event object
 */
function openMobileMenu(e) {
  if (e) {
    e.preventDefault();
  }

  // trigger to fetch subnav or update the cache
  updateCache && updateCache();

  const $mainMenu = $('.main-menu');

  $(this).attr('aria-expanded', 'true');
  $mainMenu.toggleClass('in');

  $body.add($html).addClass('mobile-menu-open');
  $('.modal-background').on('click', closeMobileMenu);

  $mainMenu.removeClass('d-none');
  $mainMenu.attr('aria-hidden', 'false');
  $mainMenu.attr('aria-modal', 'true');
  $mainMenu.siblings().attr('aria-hidden', 'true');
  $('header').siblings().attr('aria-hidden', 'true');

  let bfxContainer = $('.navbar .nav .bfx-cc');
  if (!bfxContainer.length) {
    bfxContainer = $('<li class="nav-item nav-item-secondary d-lg-none bfx-cc"></li>');
    $('.navbar .nav').append(bfxContainer);
  }

  if (bfxContainer.is(':empty')) {
    bfxContainer.append($('#bfx-cc-wrapper'));
  }

  $('.livechat-wrapper').hide();
  $('.cart-and-ipay').hide();
  $('.navbar-toggler').blur();
  window.setTimeout(function () {
    const visibleNavElements = getFocusableNavElements();
    if (visibleNavElements.length) {
      visibleNavElements[0].focus();
    }
  }, 100);

  pageScrollManager.preventPageScroll(true);
  addWindowChangeListeners();
  window.formFields.findInsiders($('#sg-navbar-collapse'));
}

/**
 * Closes the small screen menu
 * @param {*} e The Event object
 */
function closeMobileMenu(e) {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
  const $mainMenu = $('.main-menu');

  $('.menu-toggleable-left').removeClass('in');
  $body.add($html).removeClass('mobile-menu-open');
  $('.modal-background').off('click', closeMobileMenu);

  $('.navbar-toggler').attr('aria-expanded', 'false').focus();

  $mainMenu.attr('aria-hidden', 'true');
  $mainMenu.siblings().attr('aria-hidden', 'false');
  $('header').siblings().attr('aria-hidden', 'false');
  $('.dropdown').removeClass(' show');
  removeWindowChangeListeners();
  $('.cart-and-ipay').show();

  const mainMenu = document.querySelector('.main-menu');

  if (mainMenu) {
    const closeL2Nav = () => {
      document.body.classList.remove(LEVEL_2_NAV_CLASS);
      mainMenu.removeEventListener('transitionend', closeL2Nav);
    };
    mainMenu.addEventListener('transitionend', closeL2Nav);
  } else {
    document.body.classList.remove(LEVEL_2_NAV_CLASS);
  }

  pageScrollManager.allowPageScroll();
}

function returnToLevelOneNav() {
  document.body.classList.remove(LEVEL_2_NAV_CLASS);
  const menuGroup = document.querySelector('.menu-group');
  const resetOpenL1NavItems = () => {
    [].slice.call(document.querySelectorAll('.navbar-nav > .nav-item.show')).forEach(item => item.classList.remove('show'));
    menuGroup.removeEventListener('transitionend', resetOpenL1NavItems);
  };
  menuGroup.addEventListener('transitionend', resetOpenL1NavItems);
}

function scrollToTop() {
  if (isDesktop()) {
    return;
  }
  document.querySelector('.main-menu').scrollTo(0, 0);
}

function showLevelTwoNav(label) {
  if (isDesktop()) {
    return;
  }

  const menuGroup = document.querySelector('.menu-group');
  if (!level2NavHeader) {
    level2NavHeader = document.createElement('h2');
    level2NavHeader.setAttribute('class', 'l2-nav-header');
    level2NavHeader.setAttribute('aria-live', 'polite');
    if (menuGroup) {
      menuGroup.insertAdjacentElement('beforebegin', level2NavHeader);
    }
  }
  level2NavHeader.innerText = label;

  // And the back button
  if (!level2BackButton) {
    level2BackButton = document.createElement('button');
    level2BackButton.setAttribute('role', 'button');
    level2BackButton.setAttribute('class', 'l2-back-button');
    level2BackButton.addEventListener('click', returnToLevelOneNav);
    const arrow = document.createElement('span');
    arrow.setAttribute('class', 'svg-06-avenue-chevron-left svg-06-avenue-chevron-left-dims');
    level2BackButton.appendChild(arrow);
    if (menuGroup) {
      level2BackButton.setAttribute('aria-label', menuGroup.dataset.goBackLabel);
      menuGroup.insertAdjacentElement('beforebegin', level2BackButton);
    }
  }

  // Slide the menu into view.
  document.body.classList.add(LEVEL_2_NAV_CLASS);
  scrollToTop();
  if (label === 'For Me') {
    menuGroup.classList.add('formemenugroup');
  } else {
    menuGroup.classList.remove('formemenugroup');
  }
}

/**
 * Binds keyboard events for the user popover menu
 */
function popoverDownKey() {
  const $children = $('.navbar-header .user .popover').children('a, button');
  if (!$children.filter(':focus').length || $children.last().is(':focus')) {
    $children.first().focus();
  } else {
    $children.filter(':focus').next().focus();
  }
}

function popoverUpKey() {
  const $children = $('.navbar-header .user .popover').children('a, button');
  if (!$children.filter(':focus').length || $children.first().is(':focus')) {
    $children.last().focus();
  } else {
    $children.filter(':focus').prev().focus();
  }
}

function hideUserPopover() {
  const popover = document.querySelector('.navbar-header .user .popover');
  const toggler = document.querySelector('.navbar-header .user .header-account-drawer-toggle');
  if (!popover) { return; }
  popover.classList.remove('show');
  toggler.setAttribute('aria-expanded', 'false');
}

function popoverHandleKeyDown(event) {
  let preventEventActions = false;

  switch (event.key) {
    case 'ArrowDown':
      popoverDownKey();
      preventEventActions = true;
      break;
    case 'ArrowUp':
      popoverUpKey();
      preventEventActions = true;
      break;
    case 'Escape':
      hideUserPopover();
      preventEventActions = true;
      break;
    default:
      return;
  }

  if (preventEventActions) {
    event.stopPropagation();
    event.preventDefault();
  }
}

function userPopoverKeyboardEvents() {
  const userLink = document.querySelector('.navbar-header .user .user-links');
  userLink.addEventListener('keydown', popoverHandleKeyDown);
}

/**
 * Shows the user popover
 */
function showUserPopover() {
  var popover = document.querySelector('.navbar-header .user .popover');
  var toggler = document.querySelector('.navbar-header .user .header-account-drawer-toggle');
  if (!popover || inputFocused) {
    inputFocused = false;
    return;
  }
  popover.classList.add('show');
  toggler.setAttribute('aria-expanded', 'true');

  // Close the minicart popover
  $('.minicart .popover').removeClass('show');

}

/**
 * Checks to see if the screen is above a certian width.
 * @return {boolean} True if large screen, otherwise false
 */
function isDesktop() {
  return window.innerWidth >= MIN_DESKTOP_WIDTH;
}

/**
 * Checks to see if the mouse moved outside of the currently active nav item.
 * Waits a beat to check user intent, and if we determine they've moved away from the menu, close it.
 * Bound to the mouseover event on the body, and only applicable to large screen treatment
 * @param {*} evt The Event Object
 */
function listenForNavExit(evt) {
  if (!activeNavItem || $.contains(activeNavItem, evt.target)) {
    clearTimeout(exitTimeout);
    return;
  }
  exitTimeout = window.setTimeout(function () {
    if (!activeNavItem || $.contains(activeNavItem, evt.target)) {
      return;
    }
    closeLargescreenMenu({ currentTarget: activeNavItem });
  }, hoverDelay);
}

/**
 * Checks the mouse position to see if it's close to the bottom edge of the window. If it is,
 * it triggers the active menu item to close.
 * @param {*} evt The event object. Bound to the mousemove event on the body when a menu is open
 */
function windowBottomEdgeCheck(evt) {
  var threshold = 12; // Pixels from the bottom edge that should trigger the menu to close
  if (!activeNavItem || window.innerHeight - evt.clientY > threshold) {
    return;
  }
  closeLargescreenMenu({ currentTarget: activeNavItem });
}

/**
 * Sizes the white underlay element that goes full width under a mega menu. Creates it if ncessary.
 * @param {jQuery} $navListElement The list element triggering the mega menu behind which to add the underlay.
 */
function setMenuUnderlay($navListElement) {
  var $underlay = $navListElement.find('> .dropdown-underlay');
  var $dropdownMenu = $navListElement.find('> .dropdown-menu');

  if (!$underlay.length) {
    $underlay = $('<div class="dropdown-underlay"></div>');
    $navListElement.append($underlay);
  }

  $underlay.height($dropdownMenu.get(0).scrollHeight + 'px');
}

/**
 * Sets the max-height value on the active mega menu so the user can always scroll
 * to the bottom of the menu, regardless of the state of the header it's attached to.
 */
function setMenuMaxHeight() {
  if (!activeNavItem || !isDesktop()) {
    return;
  }

  var dropdownMenu = activeNavItem.querySelector('.dropdown-menu');
  var underlay = activeNavItem.querySelector('.dropdown-underlay');
  var mainMenu = document.querySelector('.main-menu');
  var pageHeader = document.querySelector('header');
  var visibleHeaderHeight;

  if (mainMenu && mainMenu.classList.contains('fixed')) {
    visibleHeaderHeight = mainMenu.offsetHeight;
  } else {
    visibleHeaderHeight = Math.max(pageHeader.offsetHeight - window.scrollY, 0);
  }

  dropdownMenu.style.maxHeight = 'calc(100vh - ' + visibleHeaderHeight + 'px)';
  underlay.style.maxHeight = 'calc(100vh - ' + visibleHeaderHeight + 'px)';
  var isForMeOpen = $("#for-me.show").length
  underlay.style.minHeight = isForMeOpen ? "initial" : '520px';
}

/**
 * Swaps menu systems when a user goes from small screen to large screen
 */
function desktopMobileTransitionCheck() {
  const currentWindowWidth = window.innerWidth;
  if (previousWindowWidth === currentWindowWidth) {
    return;
  }
  if (previousWindowWidth < MIN_DESKTOP_WIDTH && currentWindowWidth >= MIN_DESKTOP_WIDTH) {
    // Transitioned to largescreen
    fetchAccountHTML();
    closeMobileMenu();
    openLargescreenMenu();
  } else if (previousWindowWidth >= MIN_DESKTOP_WIDTH && currentWindowWidth < MIN_DESKTOP_WIDTH) {
    fetchAccountHTMLMobile();
    closeLargescreenMenu({ currentTarget: activeNavItem });
    openMobileMenu();
  }
  previousWindowWidth = currentWindowWidth;
}

/**
 * Binds resize and orientation change listeners
 */
function addWindowChangeListeners() {
  window.addEventListener('resize', desktopMobileTransitionCheck);
  window.addEventListener('orientationchange', desktopMobileTransitionCheck);
}

/**
 * Releases resize and orientation change listeners
 */
function removeWindowChangeListeners() {
  window.removeEventListener('resize', desktopMobileTransitionCheck);
  window.removeEventListener('orientationchange', desktopMobileTransitionCheck);
}

function unBindCollapseEvents() {
  $('.navbar-nav > .nav-item.dropdown:not(.disabled) [data-toggle="dropdown"]').off('click');
}

function bindCollapseEvents() {
  $('.navbar-nav > .nav-item.dropdown:not(.disabled) [data-toggle="dropdown"]').on('click', function (e) {
    const $this = $(this);

    if (isDesktop()) {
      if (window.isTouchscreen() && getTouchScreensOperatingSystem() !== "unknown") {
        e.preventDefault();
        e.stopPropagation();
      }
      return;
    }
    // Slide the level one nav over.
    if (e.currentTarget.matches(LEVEL_1_DROPDOWN_SELECTOR)) {
      showLevelTwoNav(e.currentTarget.parentNode.getAttribute('data-adobelaunchtopnavigation'));
    }
    $('.modal-background').show();
    if ($('.modal-background').length) {
      $('.modal-background').addClass('search-backdrop');
    }
    // copy parent element into current UL
    // copy navigation menu into view
    const $parent = $this.parent();
    const $siblingList = $this.siblings('ul');
    const $siblingListItem = $parent.siblings('li');

    if ($parent.hasClass('nav-item')) {
      $parent.toggleClass('show');
      $parent.find('li:first').focus();
      $siblingListItem.removeClass('show').find('li').removeClass('show');
      $siblingListItem.children('.nav-link').attr('aria-expanded', 'false');
      if ($parent.hasClass('show')) {
        $this.attr('aria-expanded', 'true');
      } else {
        $this.attr('aria-expanded', 'false');
      }
    } else {
      const $nextUl = $this.next('ul');
      $siblingList.not($this.next('ul')).removeClass('show');
      $nextUl.toggleClass('show');
      $parent.find('.dropdown-toggle').not($this).removeClass('opened');
      $this.toggleClass('opened');

      var mobileTiles = '';
      mobileTiles = $parent.parent().parent().parent().find('.noncattiles');

      $nextUl.focus();
      if ($siblingList.hasClass('show')) {
        $this.attr('aria-expanded', 'true');
        if ($parent.find('#for-me-by-category')[0]) {
          var catBottomBorder = '';
          catBottomBorder = $parent.parent().find('.mobilecatborder');
          var categoryHeight = $siblingList.height();
          mobileTiles.css('padding-top', categoryHeight);
          catBottomBorder.css('border-bottom', '1px solid #DDD');
        }
      } else {
        $this.attr('aria-expanded', 'false');
        if ($parent.find('#for-me-by-category')[0]) {
          var catBottomBorder = '';
          catBottomBorder = $parent.parent().find('.mobilecatborder');

          var categoryHeight = $siblingList.height();
          catBottomBorder.removeAttr('style');
          mobileTiles.removeAttr('style');
        }
      }
    }
    e.preventDefault();
  });
}

function Timeout(fn, interval) {
  var id = setTimeout(fn, interval);
  this.cleared = false;
  this.clear = function () {
    this.cleared = true;
    clearTimeout(id);
  };
}

function appendSubCategories($allSubCatergories, hoveredCategoryId) {
  var locator = '.l1-menu';
  if (isDesktop()) {
    var shopPreferenceClass = cookieUtil.getCookie('shopPreference') === 'men' ? 'show-in-mens' : 'show-in-womens';
    locator = '.l1-menu.' + shopPreferenceClass;
  }

  $(locator).not('.for-me').each(
    function (index, levelOneElement) {
      var $li = $(levelOneElement);
      var elementId = $li.attr('id');
      var subCateogry = $allSubCatergories.find('#' + elementId);
      if (subCateogry) {
        $li.children('#' + elementId + '.subCat').remove();
        subCateogry.insertAfter($li.find('.nav-link'));
      }
    }
  );
  unBindCollapseEvents();
  bindCollapseEvents();
}

const createAjaxWithCache = (options = {}) => ({ url, success, ...other }) => {
  // Expire the cache if the mainScript source changes
  const mainJsSource = options.mainJsSource || 'unidentified-mainJsSource'
  // only get the letters and number for security concerns
  const releaseIdentifier = mainJsSource.replace(/\W/g, '')
  // Expire the cache if the feature flag version changes manually
  const version = options.version || 1
  // expired by minutes using the Stale-while-revalidate strategy
  const lifeSpan = options.lifeSpan || 1
  // calculate the cache life span
  const expiredInMinutes = lifeSpan * 1000 * 60
  // the cache name used for the Cache API. It will change when the version changes
  const cacheName = `ajaxWithCache-v${version}`

  const createResponse = (data) => {
    const myHeaders = new Headers();
    myHeaders.append('cached-at', Date.now());
    myHeaders.append('release-identifier', releaseIdentifier);
    const response = new Response(data, {
      headers: myHeaders
    })
    return response
  }

  const fetchFromRemote = (subNavCache, callback) => {
    // read data from remote srouce
    return $.ajax({
      url,
      success: data => {
        // save the response to the cache store
        subNavCache && subNavCache.put(url, createResponse(data))
        // use the original success callback
        callback && callback(data)
      },
      ...other
    })
  }

  if ('caches' in window) {
    return caches.open(cacheName).then(subNavCache => {
      return subNavCache.match(url).then(response => {
        if (response) {
          const releaseVersionMatch = response.headers.get('release-identifier') === releaseIdentifier
          const cacheAt = new Date(Number(response.headers.get('cached-at')))
          const expiredAt = cacheAt.getTime() + expiredInMinutes
          const isExpired = Date.now() > expiredAt

          if (!releaseVersionMatch) {
            return caches.delete(url).then(() => {
              // Caches deleted due to the unmatched released version
              return fetchFromRemote(subNavCache, success)
            })
          }

          if (isExpired) {
            // Caches expired fetching updated data from remote
            fetchFromRemote(subNavCache)
            return response.text().then(success)
          }

          return response.text().then(success)
        } else {
          // Caches: not existed, fetching data from remote
          fetchFromRemote(subNavCache, success)
        }
      })
    })
  } else {
    // browser cache API is not supported, fetching data from remote
    return fetchFromRemote(null, success)
  }
}

const ajaxWithCache = createAjaxWithCache({
  lifeSpan: $('input#ajaxWithCacheLifeSpan') && $('input#ajaxWithCacheLifeSpan').val(),
  version: $('input#ajaxWithCacheVersion') && $('input#ajaxWithCacheVersion').val(),
  mainJsSource: (($("#mainScript") && $("#mainScript")[0]) || []).src
})

function openSubNav($dropdownMenu, evt) {
  var id = $dropdownMenu.attr('id');
  const NavUrl = document.getElementById('subCategoriesUrl').href;
  ajaxWithCache({
    url: NavUrl,
    type: 'get',
    success: function (data) {
      var el = document.createElement('html');
      el.innerHTML = data;
      appendSubCategories($(el), id);
      if (isDesktop()) {
        enterTimeout = new Timeout(function () {
          openLargescreenMenu(evt);
        },
          activeNavItem ? 0 : hoverDelay
        );
      }
      sessionStorage.setItem('subCategoriesFetched', true);
      $('#' + $dropdownMenu.attr('id') + '.subCat').spinner().stop();
    },
    error: function (err) {
      sessionStorage.setItem('subCategoriesFetched', false);
      $('#' + $dropdownMenu.attr('id') + '.subCat').spinner().stop();
    }
  });
}

/**
 * Opens a large screen mega menu treatment.
 * @param {*} evt The event object. The currentTarget property should be the list element in the main navigation
 */
function openLargescreenMenu(evt) {
  if (!isDesktop()) {
    return;
  }
  var $navListElement = $(evt.currentTarget);
  var $navLink = $navListElement.find('[data-toggle="dropdown"]');
  var $dropdownMenu = $navListElement.find('> .dropdown-menu');

  $navListElement.addClass('show');
  $dropdownMenu.addClass('show');
  $dropdownMenu.get(0).scrollTop = 0;
  addMenuOverlay();
  $navLink.attr('aria-expanded', 'true');
  document.body.classList.add('horizontal-scroll');
  activeNavItem = $navListElement.get(0);

  setMenuUnderlay($navListElement);
  setMenuMaxHeight();

  window.clearTimeout(enterTimeout);
  document.body.addEventListener('mouseover', listenForNavExit);
  document.body.addEventListener('mousemove', windowBottomEdgeCheck);
  window.addEventListener('scroll', setMenuMaxHeight);
  addWindowChangeListeners();
}

/**
 * Closes a large screen mega menu treatment.
 * @param {*} evt The event object. The currentTarget property should be the list element in the main navigation
 */
function closeLargescreenMenu(evt) {
  if (!isDesktop()) {
    return;
  }
  var $navListElement = $(evt.currentTarget);
  var $navLink = $navListElement.find('[data-toggle="dropdown"]');
  var $dropdownMenu = $navListElement.find('> .dropdown-menu');

  $navListElement.removeClass('show');
  $dropdownMenu.removeClass('show');
  $navLink.attr('aria-expanded', 'false');
  removeMenuOverlay();
  activeNavItem = null;
  document.body.classList.remove('horizontal-scroll');
  document.body.removeEventListener('mouseover', listenForNavExit);
  document.body.removeEventListener('mousemove', windowBottomEdgeCheck);
  window.removeEventListener('scroll', setMenuMaxHeight);
  removeWindowChangeListeners();
}

/**
 * Closes all open largescreen screen mega menu treatments, except the one determined to be currently active.
 * @param {*} evt The event object. The currentTarget property should be a list element in the main navigation
 */
function closeAllLargescreenMenus(evt) {
  if (!isDesktop() || !activeNavItem) {
    return;
  }
  $('.navbar-nav > li.dropdown.show').each(function () {
    // Skip over closing the current menu
    if (evt.currentTarget === this) {
      return;
    }
    closeLargescreenMenu({ currentTarget: this });
  });
}

/**
 * Handles tap events on the navbar on touchscreen capable devices.
 */
function bindNavTouchEvents() {
  if (!window.isTouchscreen()) {
    return;
  }

  let touchStarted = false;
  let cachedX = 0;
  let cachedY = 0;

  $body
    .on('touchstart', DROPDOWN_NAV_ITEMS_SELECTOR + ' > a, ' + DROPDOWN_NAV_ITEMS_SELECTOR + ' > span', function (e) {
      e.preventDefault();
      cachedX = e.pageX;
      cachedY = e.pageY;
      touchStarted = true;
      setTimeout(function () {
        if (!touchStarted && Math.abs(cachedX - e.pageX) < 3 && Math.abs(cachedY - e.pageY) < 3) {
          // If we get here, we can consider this a tap event.
          if (!isDesktop()) {
            return;
          }
          evt.preventDefault();
          var listItem = evt.currentTarget.parentNode;
          if (activeNavItem && activeNavItem === listItem) {
            closeLargescreenMenu({ currentTarget: listItem });
            evt.currentTarget.blur();
          } else {
            closeAllLargescreenMenus({ currentTarget: listItem });
            openLargescreenMenu({ currentTarget: listItem });
          }
        }
      }, 200);
    })
    .on('touchend touchcancel', function (e) {
      touchStarted = false;
    });
}

const updateCache = () => {
  if (remoteNavEnabled) {
    // update the cache in the background
    const NavUrl = document.getElementById('subCategoriesUrl').href;
    ajaxWithCache({
      url: NavUrl,
      type: 'get',
      success: function () { },
      error: function () { }
    });
  }
}

// After all resources loads, update the cache in the background
if (remoteNavEnabled) {
  $(window).on('load', updateCache)
}

/**
 * Handles mouse events on the main nav.
 */
function bindNavMouseEvents() {
  $body
    .on('mouseenter', DROPDOWN_NAV_ITEMS_SELECTOR, function (evt) {

      clearTimeout(exitTimeout);
      enterTimeout = new Timeout(function () {
        closeAllLargescreenMenus(evt);
        openLargescreenMenu(evt);
      },
        activeNavItem ? 0 : hoverDelay
      );

      var $navListElement = $(evt.currentTarget);
      var $dropdownMenu = $navListElement.find('> .dropdown-menu');

      if (remoteNavEnabled) {
        if (sessionStorage.subCategoriesFetched !== 'true') {
          $('#' + $dropdownMenu.attr('id') + '.subCat').spinner().start();
          openSubNav($dropdownMenu, evt);
        }
      }
    })
    .on('mouseleave', DROPDOWN_NAV_ITEMS_SELECTOR, function (evt) {
      if ((remoteNavEnabled && sessionStorage.subCategoriesFetched) || !evt.currentTarget.classList.contains('show')) {
        enterTimeout.clear();
      }
    });
}

/**
 * Gets an array of focusable elements in the main nav
 * @returns {Array} array of focusable elements
 */
function getFocusableNavElements() {
  return $(FOCUSABLE_MENU_ITEM_SELECTOR).filter(':visible').toArray();
}

/**
 * Finds the next focusable element in the modal overlay and focuses that
 * @param {Element} currentItem DOM element representing the currenly focussed item.
 * @param {boolean} forward determines the direction of the next item to find. true is forward, next is back. Defaults to true.
 * @returns {Element} The next focusable element.
 */
function getNextFocusableItem(currentItem, forward) {
  var focusableItems = getFocusableNavElements();
  var currentItemIndex = Array.prototype.indexOf.call(focusableItems, currentItem);
  var nextItem;
  forward = typeof forward === 'boolean' ? forward : true;
  if (!focusableItems.length) {
    return currentItem;
  }
  if (currentItemIndex === -1) {
    return focusableItems[forward ? 0 : focusableItems.length - 1];
  }
  switch (currentItemIndex) {
    case 0:
      nextItem = forward ? focusableItems[1] : focusableItems[focusableItems.length - 1];
      break;
    case focusableItems.length - 1:
      nextItem = forward ? focusableItems[0] : focusableItems[currentItemIndex - 1];
      break;
    default:
      nextItem = forward ? focusableItems[currentItemIndex + 1] : focusableItems[currentItemIndex - 1];
      break;
  }
  return nextItem;
}

const keystrokeHandlers = {
  largescreen: {
    down: function (menuLink) {
      const menuItem = menuLink.parent();
      if (menuItem.hasClass('nav-item')) {
        // top level
        openLargescreenMenu({ currentTarget: menuItem });
        menuItem.find('ul > li > a').first().focus();
      } else {
        menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
        if (!(menuItem.next().length > 0)) {
          // if this is the last menuItem
          menuItem
            .parent()
            .parent()
            .find('li > a') // set focus to the first menuitem
            .first()
            .focus();
        } else {
          menuItem.next().children().first().focus();
        }
      }
    },
    up: function (menuLink) {
      const menuItem = menuLink.parent();
      if (menuItem.hasClass('nav-item')) {
        // top level
        closeLargescreenMenu({ currentTarget: menuItem });
      } else if (menuItem.prev().length === 0) {
        // first menuItem
        menuItem.parent().parent().removeClass('show').children('.nav-link').attr('aria-expanded', 'false');
        menuItem
          .parent()
          .children()
          .last()
          .children() // set the focus to the last menuItem
          .first()
          .focus();
      } else {
        menuItem.prev().children().first().focus();
      }
    },
    left: function (menuLink) {
      const menuItem = menuLink.parent();
      if (menuItem.hasClass('nav-item')) {
        // top level
        closeAllLargescreenMenus({ currentTarget: menuItem });
        const next = getNextFocusableItem(menuLink.get(0), false);
        next.focus();
      } else {
        menuItem.closest('.show').removeClass('show').closest('li.show').removeClass('show').children().first().focus().attr('aria-expanded', 'false');
      }
    },
    right: function (menuLink) {
      const menuItem = menuLink.parent();
      if (menuItem.hasClass('nav-item')) {
        // top level
        closeAllLargescreenMenus({ currentTarget: menuItem });
        const next = getNextFocusableItem(menuLink.get(0));
        next.focus();
      } else if (menuItem.hasClass('dropdown')) {
        menuItem.addClass('show').children('.dropdown-menu').addClass('show');
        $(this).attr('aria-expanded', 'true');
        menuItem.find('ul > li > a').first().focus();
      }
    },
    escape: function (menuItem) {
      var parentMenu = menuItem.hasClass('show') ? menuItem : menuItem.closest('li.show');
      parentMenu.children().first().focus();
      closeLargescreenMenu({ currentTarget: parentMenu });
    }
  },
  smallscreen: {
    down: function (menuLink) {
      const next = getNextFocusableItem(menuLink.get(0));
      next.focus();
    },
    up: function (menuLink) {
      const next = getNextFocusableItem(menuLink.get(0), false);
      next.focus();
    },
    right: function (menuLink) {
      const next = getNextFocusableItem(menuLink.get(0));
      next.focus();
    },
    left: function (menuLink) {
      const next = getNextFocusableItem(menuLink.get(0), false);
      next.focus();
    },
    escape: function () {
      closeMobileMenu();
    }
  }
};

/**
 * Finds the appropriate handler function in the keystrokeHandlers object and fires that function
 * @param {string} key The keystroke name that maps to a property name in the keystrokeHandlers object (e.g. 'down', 'up', 'escape')
 * @param {jQuery} menuItem The menu item which the keystroke was bound to.
 * @param {Element} scope The scope from which the function should be called.
 */
function keystrokeHandlerForLayout(key, menuItem, scope) {
  const handler = keystrokeHandlers[isDesktop() ? 'largescreen' : 'smallscreen'];
  if (typeof handler[key] === 'function') {
    handler[key].call(scope, menuItem);
  }
}

/**
 * @function
 * @description appends the parameters to the given url and returns the changed url
 * @param {string} url the url to which the parameters will be added
 * @param {Object} name of the anchor tag
 * @param {string} value the url to which the parameters will be added
 * @returns {Object} url element.
 */
function appendParamToURL(url, name, value) {
  // quit if the param already exists
  /* eslint-disable */
  if (url.indexOf(name + '=') !== -1) {
    return url;
  }
  value = decodeURIComponent(value);
  var separator = url.indexOf('?') !== -1 ? '&' : '?';
  return url + separator + name + '=' + encodeURIComponent(value);
  /* eslint-disable */
}

function addEventsToUserLinks() {
  const userLinks = document.querySelector('.navbar-header .user-links');
  if (!userLinks) { return; }

  userLinks.addEventListener('mouseenter', showUserPopover);
  userLinks.addEventListener('mouseleave', hideUserPopover);
  userLinks.addEventListener('focusin', showUserPopover);
}

function getTouchScreensOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (['iPad Simulator', 'iPad'].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)) {
    return "iOS";
  }
  return "unknown";
}

function handleSaksPlusNavInputFocus() {
  $('.saks-plus-nav input#login-form-email , .saks-plus-nav input#login-form-password').on('focusin', function () {
    inputFocused = false;
    if (!$('.popover.popover-bottom.saks-plus-sigin').hasClass('show')) {
      inputFocused = true;
    }
  });

  $('form.saks-plus-nav').submit(function (e) {
    e.preventDefault();
    var form = $(this);
    // eslint-disable-next-line no-undef
    var url = form.attr('action');
    const $saksPlusNav = $('form.saks-plus-nav');
    $saksPlusNav.trigger('login:submit', e);
    $.ajax({
      url: url,
      type: 'post',
      dataType: 'json',
      data: form.serialize(),
      success: function (data) {
        if (!data.success) {
          formValidation(form, data);
          $saksPlusNav.trigger('login:error', data);
        } else {
          $saksPlusNav.trigger('login:success', data);

          if ($('.page').data('action') === 'Login-Show') {
            window.location = $('#LoginForm').data('account-url');
            return;
          } else {
            window.location.reload();
          }



        }
        if (data.botError) {
          $saksPlusNav.find('button.account-btn').attr('disabled', 'disabled');
          $saksPlusNav.find('button.sign-up-btn').attr('disabled', 'disabled');
        }
      },
      error: function (data) {
        if (data.responseJSON.action == 'CSRF-AjaxFail') {
          window.location.href = data.responseJSON.failLoginRedirectURL;
        } else if (data.responseJSON.redirectUrl) {
          window.location.href = data.responseJSON.redirectUrl;
        } else {
          $saksPlusNav.trigger('login:error', data);
          // form.spinner().stop();
        }
        if (data.botError) {
          $saksPlusNav.find('button.account-btn').attr('disabled', 'disabled');
          $saksPlusNav.find('button.sign-up-btn').attr('disabled', 'disabled');
        }
      }
    });

    return false;
  });
}

function headerAccPopover() {
  function navigateAccPg(link) {
    var $link = $(link);
    var $popover = $link.next('.popover');

    if ($popover.hasClass('logged-out-user')) {
      window.location = $link.next('.popover.logged-out-user').find('.signin-link').attr('href');
    } else if ($popover.hasClass('logged-in-user')) {
      window.location = $link.next('.popover.logged-in-user').find('.my-account').attr('href');
    }
  }

  const wlcsignin = document.querySelector('.navbar-header .user-links .header-account-drawer-toggle');

  if (wlcsignin) {
    $(wlcsignin).on('click', function () {
      navigateAccPg(wlcsignin);
    });
  }
}

// User data fetching and inserting methods
let accountHTMLFetched = false;
let accountHTMLFetchedMobile = false;

function insertAccountHTML(data) {
  $('.account-header').html(data);
  addEventsToUserLinks();
  userPopoverKeyboardEvents();
  headerAccPopover();
  if (window.pageData.page.type === 'product detail') {
    handleSaksPlusNavInputFocus();
  }
  accountHTMLFetched = true;
}

function insertAccountHTMLMobile(data) {
  $('.mobile-account-header').before(data);
  accountHTMLFetchedMobile = true;
}

function insertMiniCartHTML(data) {
  $('.minicart.minicart-popup-container').empty().append(data);
}

function insertSaksFirstHTML(data) {
  $('.saks-first-card .custom-tooltip .tooltip-content').empty().append(data);
}

function updateWishListData(data) {
  var $productContainer = $body.find('.product-detail');
  persistentWishlist.updateWishlistContent($productContainer, data);
}

function onFetchUserData(response, requestedElements) {
  const $response = $(response);

  requestedElements.forEach(item => {
    if (item === 'minicart') {
      const miniCart = $response.filter('#js-pageComponent_miniCart').html();
      insertMiniCartHTML(miniCart);
    }
    if (item === 'accountHeader') {
      const accountHeader = $response.filter('#js-pageComponent_accountHeader').html();
      insertAccountHTML(accountHeader);
    }
    if (item === 'mobileAccountHeader') {
      const mobileAccountHeader = $response.filter('#js-pageComponent_mobileAccountHeader');
      if(mobileAccountHeader.find('.wishi-remove-on-sign-in.d-lg-none').length > 0 && $('.menu-group .nav').find('.wishi-remove-on-sign-in.d-lg-none').length > 0) {
        $('.menu-group .nav').find('.wishi-remove-on-sign-in.d-lg-none').remove();
      }
      insertAccountHTMLMobile(mobileAccountHeader.html());
    
    }
    if (item === 'saksfirstpdp') {
      const saksfirstpdp = $response.filter('#js-pageComponent_saksFirstPDP').html();
      insertSaksFirstHTML(saksfirstpdp);
    }
    if (item === 'wishlistpdp') {
      const wishlistpdp = $response.filter('#js-pageComponent_wishlistpdp').html();
      updateWishListData(JSON.parse(wishlistpdp));
    }
  });
  
  // Bread Financial scripts
  if (window.SitePreferences.breadEnabled) {
    const processInclude = require('base/util');
    processInclude(require('bread/breadScript'));
    processInclude(require('bread/responseHandler'));
  }
}

function fetchUserData(requestedElements) {
  const url = $('.js-user-components').data('user-components-url');
  if (!url) { return; }

  var scope = 'global';
  var data = {
    scope: scope,
    requestedElements: JSON.stringify(requestedElements)
  };

  if (window.pageData.page.type === 'product detail') {
    var $productContainer = $body.find('.product-detail');
    data.pid = $productContainer.data('pid') || '';
    data.scope = 'pdp';
  }

  $.ajax({
    url: url,
    method: "POST",
    data: data,
    dataType: "html",
    success: (response) => onFetchUserData(response, requestedElements)
  });
}

function menuDependingFromBreakpoint() {
  const windowInnerWidth = window.innerWidth;
  let endpoint = 'mobileAccountHeader';

  if (windowInnerWidth >= MIN_DESKTOP_WIDTH || window.mobileApp) {
    endpoint = 'accountHeader';
  }

  return endpoint;
}

function fetchMenuHandler() {
  const menuType = menuDependingFromBreakpoint();
  if ((menuType === 'mobileAccountHeader' && accountHTMLFetchedMobile) || (menuType === 'accountHeader' && accountHTMLFetched)) {
    return;
  }
  fetchUserData([menuType]);
}

function addMenWomanCategory() {
  // add mens vs womens class to the body of the page
  const shopPreference = cookieUtil.getCookie('shopPreference') || 'women';

  // the body tag classes was getting cached on production, hence doing it via JS as well.
  const shopPrefClassOnBody = shopPreference === 'men' ? 'hide-womens' : 'hide-mens';
  $body.removeClass('hide-womens hide-mens').addClass(shopPrefClassOnBody);
  try {
    // The cache headers on the home requests prevent from performing a redirect.
    // Hence, if we have a host setup, the path is updated for the logo anchor
    const $userLinks = $('.user-links');
    const $waitListEmail = $('.js-waitlist-email');
    if (shopPreference) {
      const shopPreferenceURL = $('.page').data(shopPreference === 'men' ? 'homemen' : 'homewomen');
      $body.find('a.logo-home').attr('href', shopPreferenceURL);
    }
    if ($userLinks.length && $waitListEmail.length) {
      const email = $userLinks.data('useremail');
      $waitListEmail.val(email);
      const $label = $waitListEmail.closest('.form-group').find('label');
      if ($waitListEmail.val().length) {
        $label.addClass('input-focus');
      }
    }
  } catch (e) {
    console.log('did not update Home logo URL via javascript');
  }
}

module.exports = function () {
  if (window.pageData.page.type === 'product detail') {
    handleSaksPlusNavInputFocus();
  }

  // User data fetching and inserting logic
  if (!!$('.js-user-components').data('user-components-url')) {
    // Remove this condition and else when
    // UserComponentsAjaxConsolidationEnabled & PDPUserComponentsAjaxConsolidationEnabled
    // is removed
    let requestedElements = ['minicart'];
    requestedElements.push(menuDependingFromBreakpoint());
    if (window.pageData.page.type === 'product detail') {
      requestedElements.push('saksfirstpdp');
      requestedElements.push('wishlistpdp');
    }
    fetchUserData(requestedElements);

    window.addEventListener('resize', fetchMenuHandler);
  } else {

    const fetchAccountHTMLMobile = function () {
      const mobileUrl = $('.mobile-account-header').data('url');
      if (mobileUrl && !accountHTMLFetchedMobile) {
        $('.mobile-account-header').data('url', '');
        $.ajax({
          url: mobileUrl,
          type: 'get',
          success: function (data) {
            insertAccountHTMLMobile(data)
          }
        });
      }
    }

    const fetchAccountHTML = function () {
      const url = $('.account-header').data('url');
      if (url && !accountHTMLFetched) {
        $('.account-header').data('url', '');
        $.ajax({
          url: url,
          type: 'get',
          success: function (data) {
            insertAccountHTML(data)
          }
        })
      }
    }

    const fetchAccountMenu = function () {
      const windowInnerWidth = window.innerWidth;
      if (windowInnerWidth >= MIN_DESKTOP_WIDTH || window.mobileApp) {
        fetchAccountHTML();
      } else if (windowInnerWidth < MIN_DESKTOP_WIDTH) {
        fetchAccountHTMLMobile();
      }
    }

    fetchAccountMenu();
    window.addEventListener('resize', fetchAccountMenu);

    var miniCartUrl = $('.minicart.minicart-popup-container').data('get-minicart-icon');
    if (miniCartUrl) {
      const $minicartPopupContainer = $('.minicart.minicart-popup-container');
      $minicartPopupContainer.data('get-minicart-icon', '');
      $.ajax({
        url: miniCartUrl,
        type: 'get',
        success: function (response) {
          $minicartPopupContainer.html(response);
        }
      });
    }
    // Load tooltip on PDP page if PDPUserComponentsAjaxConsolidationEnabled is NOT enabled
    if (window.pageData.page.type === 'product detail') {
      const url = $('.saks-first-card .tooltip-content').data('url');
      if (url) {
        $.ajax({
          url: url,
          type: 'get',
          success: function (data) {
            $tooltipContent.html(data);
          }
        });
      }
    }
    // remove this else when UserComponentsAjaxConsolidationEnabled & PDPUserComponentsAjaxConsolidationEnabled is removed
  }

  // User data fetching and inserting logic end

  var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
  const $headerBanner = $('.header-banner');
  $headerBanner.find('.close').on('click', function () {
    $headerBanner.addClass('d-none');
    window.sessionStorage.setItem('hide_header_banner', '1');
  });

  if (!headerBannerStatus || headerBannerStatus < 0) {
    $headerBanner.removeClass('d-none');
  }

  // TODO
  keyboardAccessibility(
    FOCUSABLE_MENU_ITEM_SELECTOR,
    {
      40: function (menuItem) {
        // down
        keystrokeHandlerForLayout('down', menuItem, this);
      },
      38: function (menuItem) {
        keystrokeHandlerForLayout('up', menuItem, this);
      },
      37: function (menuItem) {
        // left
        keystrokeHandlerForLayout('left', menuItem, this);
      },
      27: function (menuItem) {
        // escape
        keystrokeHandlerForLayout('escape', menuItem, this);
      }
    },
    function () {
      return $(this);
    }
  );

  sessionStorage.setItem('forMeOpened', false); //By Default on page load - False
  remoteNavEnabled && sessionStorage.setItem('subCategoriesFetched', false); //By Default on page load - False

  if (!remoteNavEnabled) {
    $('.navbar-nav > .nav-item.dropdown:not(.disabled) [data-toggle="dropdown"]').on('click', function (e) {
      const $this = $(this);
      const $modalBackground = $('.modal-background');
      if (isDesktop()) {
        if (window.isTouchscreen() && getTouchScreensOperatingSystem() !== "unknown") {
          e.preventDefault();
          e.stopPropagation();
        }
        return;
      }
      // Slide the level one nav over.
      if (e.currentTarget.matches(LEVEL_1_DROPDOWN_SELECTOR)) {
        showLevelTwoNav(e.currentTarget.parentNode.getAttribute('data-adobelaunchtopnavigation'));
      }
      $modalBackground.show();
      if ($modalBackground.length) {
        $modalBackground.addClass('search-backdrop');
      }
      // copy parent element into current UL
      // copy navigation menu into view
      const $parent = $this.parent();
      const $siblingListItem = $parent.siblings('li');
      const $siblingList = $this.siblings('ul');
      const $nextList = $this.next('ul');

      if ($parent.hasClass('nav-item')) {
        $parent.toggleClass('show');
        $parent.find('li:first').focus();
        $siblingListItem.removeClass('show').find('li').removeClass('show');
        $siblingListItem.children('.nav-link').attr('aria-expanded', 'false');
        if ($parent.hasClass('show')) {
          $this.attr('aria-expanded', 'true');
        } else {
          $this.attr('aria-expanded', 'false');
        }
      } else {
        $siblingList.not($nextList).removeClass('show');
        $nextList.toggleClass('show');
        $parent.find('.dropdown-toggle').not($this).removeClass('opened');
        $this.toggleClass('opened');

        var mobileTiles = '';
        mobileTiles = $parent.parent().parent().parent().find('.noncattiles');

        $nextList.focus();
        if ($siblingList.hasClass('show')) {
          $this.attr('aria-expanded', 'true');
          if ($parent.find('#for-me-by-category')[0]) {
            var catBottomBorder = '';
            catBottomBorder = $parent.parent().find('.mobilecatborder');
            var categoryHeight = $siblingList.height();
            mobileTiles.css('padding-top', categoryHeight);
            catBottomBorder.css('border-bottom', '1px solid #DDD');
          }
        } else {
          $this.attr('aria-expanded', 'false');
          if ($parent.find('#for-me-by-category')[0]) {
            var catBottomBorder = '';
            catBottomBorder = $parent.parent().find('.mobilecatborder');

            var categoryHeight = $siblingList.height();
            catBottomBorder.removeAttr('style');
            mobileTiles.removeAttr('style');
          }
        }
      }
      e.preventDefault();
    });
  }

  remoteNavEnabled && bindCollapseEvents();
  bindNavTouchEvents();
  bindNavMouseEvents();

  /* Changed traversing of the close button click event as per the structure change */

  $('.close-menu>.close-button').on('click', closeMobileMenu);
  $('.nav-close-menu>.close-button').on('click', closeMobileMenu);

  $body.on('click', '.close-button', function (e) {
    const $navBarNav = $('.navbar-nav');
    const $modalBackground = $('.modal-background');
    e.preventDefault();
    $navBarNav.find('.top-category').detach();
    $navBarNav.find('.nav-menu').detach();
    $navBarNav.find('.show').removeClass('show');
    $('.menu-toggleable-left').removeClass('in');

    $('.main-menu').siblings().attr('aria-hidden', 'false');
    $('header').siblings().attr('aria-hidden', 'false');

    if ($modalBackground.length) {
      $modalBackground.removeClass('search-backdrop');
    }
    $modalBackground.hide();
    $('.livechat-wrapper').show();
    $('.cart-and-ipay').show();
  });

  $('.navbar-toggler').click(openMobileMenu);

  addEventsToUserLinks();

  if ($(window).width() >= 1023.99) {
    $('.navbar .dropdown-item.d-lg-none').remove();
  }

  if (isDesktop()) {
    const visibleNavItems = document.querySelectorAll('.navbar-nav > li.dropdown:not(.d-lg-none)');
    const last = visibleNavItems[visibleNavItems.length - 1];
    if (typeof last !== 'undefined') {
      last.classList.add('d-last-visible');
    }
  }

  if (!isDesktop()) {
    $('.dropdown-link.dropdown-toggle').each(function () {
      const $this = $(this);
      if ($this.next('ul').children().length === $this.next('ul').find('li.d-lg-block').length) {
        $this.removeClass('dropdown-toggle').unbind('click');
      }
    });
  }
  $body.on('click', '.shop-preference .cat-heads', function (e) {
    const $this = $(this);

    e.preventDefault();

    if (isDesktop() || (!isDesktop() && !$this.hasClass('active'))) {
      $('.cat-heads').removeClass('active');
      $this.addClass('active');
      var url = $this.closest('.shop-preference').data('url');
      var shopperPreference = $this.data('type');
      url = appendParamToURL(url, 'shopperPreference', shopperPreference);
      $.ajax({
        url: url,
        success: function (response) {
          if (isDesktop()) {
            window.location.href = response.redirectUrl;
          } else {
            history.pushState({}, '', response.redirectUrl);
            $body.toggleClass('hide-mens').toggleClass('hide-womens');
          }
        }
      });
    }
  });

  window.setTimeout(addMenWomanCategory, 0); // just move out of task queue to optimize long function call
};
