var atmHelper = require('./atmHelper');

function fetchVisitorData() {
  const visitorDataURL = $('#visitorDataURL').val();
  if (visitorDataURL && visitorDataURL.length) {
    $.ajax({
      url: visitorDataURL,
      method: 'GET',
      dataType: 'json',
      success: function (data) {
        window.globalData.visitor = data.visitorData;
        atmHelper.updateGlobalData();
        $('body').trigger('adobeTagManager:visitorDataPopulated', { visitorData: data.visitorData });
      }
    });
  }
}

module.exports = fetchVisitorData;
