'use strict';
var base = require('base/login/login');
var formValidation = require('../components/formValidation');
var createErrorNotification = require('base/components/errorNotification');
const clientSideValidation = require('../components/clientSideValidation');

base.login = function () {
  $('form.login').submit(function (e) {
    e.preventDefault();
    var form = $(this);
    // eslint-disable-next-line no-undef
    grecaptcha.ready(function () {
      // eslint-disable-next-line no-undef
      grecaptcha.execute($('.google-recaptcha-key').html(), { action: 'login' }).then(function (token) {
        $('.g-recaptcha-token').val(token);
        var url = form.attr('action');
        form.spinner().start();
        $('form.login').trigger('login:submit', e);
        $.ajax({
          url: url,
          type: 'post',
          dataType: 'json',
          data: form.serialize(),
          success: function (data) {
            form.spinner().stop();
            if (!data.success) {
              formValidation(form, data);
              $('form.login').trigger('login:error', data);
            } else {
              $('form.login').trigger('login:success', data);
              location.href = data.redirectUrl;
            }
            if (data.botError) {
              $('form.login button.account-btn').attr('disabled', 'disabled');
              $('form.login button.sign-up-btn').attr('disabled', 'disabled');
            }
          },
          error: function (data) {
            if (data.responseJSON.redirectUrl) {
              window.location.href = data.responseJSON.redirectUrl;
            } else {
              formValidation(form, data.responseJSON);
              $('form.login').trigger('login:error', data);
              form.spinner().stop();
            }
            if (data.botError) {
              $('form.login button.account-btn').attr('disabled', 'disabled');
              $('form.login button.sign-up-btn').attr('disabled', 'disabled');
            }
          }
        });
        return false;
      });
    });
  });
};

base.resetPassword = function () {
  $('body .reset-password-form').submit(function (e) {
    e.preventDefault();
    var form = $(this);
    // eslint-disable-next-line no-undef
    grecaptcha.ready(function () {
      // eslint-disable-next-line no-undef
      grecaptcha.execute($('.google-recaptcha-key').html(), { action: 'forgotpassword' }).then(function (token) {
        $('.g-recaptcha-token').val(token);

        var url = form.attr('action'),
            email = $('#reset-password-email').val();

        form.spinner().start();
        $('.reset-password-form').trigger('login:register', e);
        $.ajax({
          url: url,
          type: 'post',
          dataType: 'json',
          data: form.serialize()
        });
        form.spinner().stop();
        $('.js-forgot-container').remove();
        $('#reset-message1').text(window.Resources.resetPasswordMessage1);
        $('#reset-message2').text((window.Resources.resetPasswordMessage2 || '').replace('{0}', email));
        $('.js-container-success').removeClass('d-none');

        return false;
      });
    });
  });
};

base.validatePassword = function () {
  $('body').on('keyup', '#newPassword, #registration-form-password', function (event) {
    var regex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#_?!@$%^()+=><~`}{|&*-])^[^'<>/]{8,}$/);
    if ($(this).val() !== '') {
      $('.password-condition').removeClass('d-none');
      if (regex.test($(this).val())) {
        $('.password-condition').addClass('d-none');
      } else {
        $('.password-condition').removeClass('d-none');
      }
    } else {
      $('.password-condition').addClass('d-none');
    }
    if ($(event.target).is('#newPassword') && $(this).closest('.reset-password-page').length > 0) {
      clientSideValidation.validateFormonBlur.call(this, event);
    }
  });
};

base.submitNewPassword = function () {
  $('body').on('click', '.submit-new-password', function (e) {
    e.preventDefault();
    // eslint-disable-next-line no-undef
    grecaptcha.ready(function () {
      // eslint-disable-next-line no-undef
      grecaptcha.execute($('.google-recaptcha-key').html(), { action: 'updatepassword' }).then(function (token) {
        $('.g-recaptcha-token').val(token);
        var regex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
        var password = $('#newPassword').val();
        if (password !== '') {
          if (!regex.test(password)) {
            $('#newPassword').addClass('is-invalid');
          } else {
            $('form.password-reset-form').submit();
          }
        } else {
          $('.password-condition').addClass('d-none');
        }
      });
    });
  });
};

base.register = function () {
  $('form.registration').submit(function (e) {
    e.preventDefault();

    var form = $(this);

    // eslint-disable-next-line no-undef
    grecaptcha.ready(function () {
      // eslint-disable-next-line no-undef
      grecaptcha.execute($('.google-recaptcha-key').html(), { action: 'register' }).then(function (token) {
        $('.g-recaptcha-token').val(token);

        var url = form.attr('action');
        form.spinner().start();
        var data = form.serialize();
        var rewardNo = $('#registration-form-hudsonbay-rewards').val();
        if (rewardNo !== undefined && rewardNo !== '') {
          var hbcCard = $('#registration-form-hudsonbay-rewards').val();
          data = data + '&hbcRewardNumber=' + hbcCard;
        }

        var confirmpwd = $('.registration #registration-form-password-confirm');
        ConfirmpwdValidation(confirmpwd);

        $('form.registration').trigger('login:register', e);
        $.ajax({
          url: url,
          type: 'post',
          dataType: 'json',
          data: data,
          success: function (resp) {
            form.spinner().stop();
            if (!resp.success) {
              formValidation(form, resp);
            } else {
              location.href = resp.redirectUrl;
            }
            if (resp.botError) {
              $('form.registration button.account-btn').attr('disabled', 'disabled');
            }
          },
          error: function (err) {
            if (err.responseJSON.redirectUrl) {
              window.location.href = err.responseJSON.redirectUrl;
            } else {
              createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
            }
            if (err.botError) {
              $('form.registration button.account-btn').attr('disabled', 'disabled');
            }

            form.spinner().stop();
          }
        });
        return false;
      });
    });
  });
};

base.enableOptInCheckbox = function () {
  $('.js-canadian-customer').on('change', function () {
    if ($('.js-canadian-customer:checked').val() === 'T') {
      $('.js-email-pref-checkobox').removeClass('d-none');
      $('.saks-canada-opt').prop('checked', false);
      $('.js-saks-canadat-opt').addClass('d-none');
      $('.create-apply-btn-label').addClass('d-none');
      $('.create-btn-label').removeClass('d-none');
    } else {
      $('.js-email-pref-checkobox').addClass('d-none');
      $('.js-saks-canadat-opt').removeClass('d-none');
      $('#add-to-email-list').prop('checked', false);
      $('.create-apply-btn-label').removeClass('d-none');
      $('.create-btn-label').addClass('d-none');
    }
  });
};

/**
 * validate email and confirm email on blur
 *
 */
function ConfirmpwdValidation($this) {
  var emailval = $('.registration #registration-form-password').val();
  var confirmemailval = $($this).val();
  if (emailval !== confirmemailval) {
    $($this).addClass('is-invalid');
    $($this).closest('.registration').find('label[for="registration-form-password-confirm"]').addClass('is-invalid');
    if ($($this).next('span').length === 0) {
      $('<span></span>').insertAfter($this);
    }
    $($this).next('span').addClass('invalid');
    if ($($this).next('span').hasClass('valid')) {
      $($this).next('span').removeClass('valid').addClass('invalid');
    }
    $($this).parents('.form-group').find('.invalid-feedback').text($($this).data('pattern-pwd-mismatch'));
  }
  return false;
}

base.pwdValidation = function () {
  $('.registration #registration-form-password-confirm').on('blur', function () {
    ConfirmpwdValidation($(this));
  });
};

base.validatepostalcode = function () {
  $('#registration-form-zipcode').on('blur', function () {
    if ($(this).val() !== '') {
      // If Zip Code is there, make it required for the validation.
      $(this).prop('required', true);
      var validate;
      if ($(this).hasClass('us-pattern')) {
        $(this).attr('pattern', '^[0-9]{5}(-[0-9]{4})?$');
        validate = /(^\d{5}(-\d{4})?$)/.test($(this).val()) === false;
      } else if ($(this).hasClass('ca-pattern')) {
        $(this).attr('pattern', '^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$');
        validate = /^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/.test($(this).val()) === false;
      }
      if (validate) {
        $(this).addClass('is-invalid');
        $(this).prev('label').addClass('is-invalid');
      } else {
        $(this).removeClass('is-invalid');
        $(this).prev('label').removeClass('is-invalid');
        if ($(this).hasClass('ca-pattern')) {
          // eslint-disable-next-line newline-per-chained-call
          var zipVal = $(this).val().trim().replace(/\s/g, '').toUpperCase();
          var formatedVal = zipVal.substr(0, 3) + ' ' + zipVal.substr(3, zipVal.length);
          $(this).val(formatedVal);
        }
      }
    } else if ($(this).hasClass('is-invalid')) {
      $(this).prop('required', false);
      $(this).removeClass('is-invalid');
      $(this).prev('label').removeClass('is-invalid');
      $(this).next('.invalid').remove();
    } else {
      $(this).prop('required', false);
      $(this).next('.invalid').remove();
    }
  });
};

base.validateHudsonNumner = function () {
  $('body').on('blur', '.register-page .hudson-reward', function () {
    var $this = $(this);
    var rewardNo = $this.val();
    var url = $this.data('check-balance-url');
    var regex = new RegExp('^[0-9]+$');
    if (rewardNo && url && regex.test(rewardNo)) {
      $.spinner().start();
      $.ajax({
        url: url,
        type: 'POST',
        data: {
          reward: rewardNo,
          check: true
        },
        success: function (data) {
          if (!data.success) {
            $('.hudson-error').removeClass('d-none');
            $('.hudson-reward').val('');
            $('.hudson-reward').addClass('is-invalid');
            $('label.hudsonbay-rewards').addClass('is-invalid');
            $('<span class="invalid"></span>').insertAfter('.hbc-loyalty-number-prefix');
            if (data.counter >= 5) {
              $('.hudson-reward').attr('disabled', true);
              $('.hudson-error').addClass('d-none');
              $('.hudson-error-max').removeClass('d-none');
            }
          } else {
            $('.hudson-error').addClass('d-none');
            $('.hudson-reward').removeClass('is-invalid');
            $('label.hudsonbay-rewards').removeClass('is-invalid');
            $('.hbc-loyalty-number-prefix').nextAll('.invalid').remove();
          }
          $.spinner().stop();
        },
        error: function () {
          $('.hudson-error').removeClass('d-none');
          $('.hudson-reward').val('');
          $('.hudson-reward').addClass('is-invalid');
          $('label.hudsonbay-rewards').addClass('is-invalid');
          $('<span class="invalid"></span>').insertAfter('.hudson-reward');
          $.spinner().stop();
        }
      });
    } else {
      $('.hudson-error').removeClass('d-none');
      $('label.hudsonbay-rewards').addClass('is-invalid');
      $('.hudson-reward').addClass('is-invalid');
      $('.hudson-reward').val('');
    }
  });
};

module.exports = base;
