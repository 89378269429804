'use strict';

var login = require('core/login/login');
var cookiesUtil = require('core/components/utilhelper');
let placementId = window.SitePreferences && window.SitePreferences.breadLandingPlacementId;
let breadEnabled = window.SitePreferences && window.SitePreferences.breadEnabled;
let breadEnv = window.SitePreferences && window.SitePreferences.breadEnvironment;
let storeNumber = window.SitePreferences && window.SitePreferences.breadStoreNumber;
let breadLandingCobrandPlacementId = window.SitePreferences && window.SitePreferences.breadLandingCobrandPlacementId;
/**
 * Generates the modal window on the first call.
 * @param {Object} data - render data
 */
function appendModalHtmlElement(data) {
  if ($('#signInApplyModal').length !== 0) {
    $('#signInApplyModal').empty();
  }
  var htmlString =
    '<!-- Modal -->' +
    '<div class="modal-dialog">' +
    '<div class="modal-content">' +
    '<div class="modal-body">' +
    '<div class="cancel-icon">' +
    '<button type="button" class="close svg-svg-22-cross svg-svg-22-cross-dims" data-dismiss="modal" aria-label="Close Modal Box"></button>' +
    '</div>' +
    '<div class="no-gutters modal-row align-items-start modal-contents">' +
    '<div class="modal-column">' +
    data +
    '</div>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '</div>';
  if ($(document).find('#signInApplyModal').length) {
    $(document).find('#signInApplyModal').append(htmlString);
  }
}

/**
 * open login model
 * @param {string} url url to be open with
 */
function openLoginPopup(url) {
  $('body').spinner().start();
  $.ajax({
    url: url,
    dataType: 'html',
    success: function (data) {
      if (data.redirect) {
        window.location.href = data.redirect;
      } else {
        appendModalHtmlElement(data);
        login.login();
        $('#signInApplyModal').modal('hide');
        setTimeout(function () {
          $('body').spinner().stop();
          $('#signInApplyModal').modal('show');
          if ($('.benefits-main-div .icons-container').length === 1) {
            $('.benefits-main-div').addClass('single-icon-container');
          } else if ($('.benefits-main-div .icons-container').length === 0) {
            $('.benefits-main-div').addClass('no-icon-content');
          }
          if ($('#signInApplyModal .new-user-section').length > 0) {
            $('#signInApplyModal .new-user-section').removeClass('invisible');
          }
        }, 200);
      }
    },
    error: function () {
      $('body').spinner().stop();
    }
  });
}
/**
 * Initial Breadmodal using value configured in custom preference 
 */
function initBreadScript(store, $parent) {
  var $customerData = $('.customer-details').data('customer');
  var cardHolder = $customerData && $customerData.breadExistingCardHolder ? $customerData.breadExistingCardHolder : false;
  var landingPlacement = {
    financingType: 'card',
    locationType: 'landing',
    placementId: placementId,
    domID: 'bread-payments-landing-placement'
  }
  
  if (store == 'true' || $parent.prop('id') === 'bread-payments-landing-cobrand-placement') {
    var domId = $parent.prop('id') || 'bread-payments-landing-cobrand-placement';
    landingPlacement.placementId = breadLandingCobrandPlacementId;
    landingPlacement.domID = domId;
  }

  BreadPayments.setup({
    storeNumber: storeNumber,
    existingCardHolder: cardHolder,
    env: breadEnv
  });

  BreadPayments.registerPlacements([landingPlacement]);
}
/**
 * opens Bread Modal
 */
function triggerModal(store, $parent) {
  if (store === true) {
    $parent = $('#bread-payments-landing-cobrand-placement');
  } else {
    $parent = $('#bread-payments-landing-placement');
  }
  initBreadScript(store, $parent);
  if ($parent) {
    $('body').spinner().start();
    var checkExist = setInterval(function () {
      //Check if bread div loaded
      if ($parent.find('.ep-text-placement').length) {
        $('body').spinner().stop();
        clearInterval(checkExist);
        $parent.find('.ep-text-placement').trigger('click');
      }
    }, 500);
  }
}

/**
 * Bread Modal primary actions. Handles initial action for guest and logged in users
 * Show login modal for Guest and display bread modal for registered users
 **/
function showBreadModal() {
  if (breadEnabled) {
    $('.bread-placement').removeClass('d-none');
    $('.bread-placement.store-login').removeClass('d-none');
    //For App users, overwrite window.confirm popup thats preventing the customer to close the modal
    if (window.FABuild) {
      window.confirm = () => true;
    }
  }
  var isUserAuthenticated = !$('.user-links .popover.popover-bottom').hasClass('logged-out-user');
  if (isUserAuthenticated && new URL(window.location.href).searchParams.get('openCobrandedPlacement') === 'true') {
    triggerModal(true, null);
  } else if (isUserAuthenticated && new URL(window.location.href).searchParams.get('breadPlacement') === 'true') {
    triggerModal(false, null);
  }
  $('body').on('click', '.bread-placement', function (e) {
    e.preventDefault();
    if (!isUserAuthenticated) {
        openLoginPopup($(this).data('loginurl'));
      // If simple login just redirect for the logged in url
    } else if ($(this).hasClass('simple-login')) {
      window.location.href = $(this).data('logged-in-url');
    } else {
        var $storelogin = $(this).hasClass('store-login');
        triggerModal($storelogin, $(e.target).parent());
      }
  });
}

module.exports = {
  showBreadModal: showBreadModal
};
