'use strict';

const debounce = require('lodash/debounce');

/**
 * Sets a style property on the document equal to 1% of the pixel height of the window.
 * That value is referenced in CSS when we need more accurate viewport height than what vh units give us.
 * This is particularly useful in mobile Safari, where the vh units don't take browser chrome into account.
 */
function calculateViewportHeight() {
  let vh = window.innerHeight / 100;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('resize', debounce(calculateViewportHeight, 20));

module.exports = calculateViewportHeight;
