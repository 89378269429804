function loadImages($lazyImage) {
  const $source = $lazyImage.parent().find('source');

  $source.each(function () {
    const $this = $(this);

    $this.attr('srcset', $this.data('srcset')).removeAttr('data-srcset');
  });
  $lazyImage.attr('src', $lazyImage.data('src')).removeAttr('data-src');
  $lazyImage.attr('srcset', $lazyImage.data('srcset')).removeAttr('data-srcset');
  $lazyImage.removeClass('lazyload lazy');
}

function lazyLoadImages() {
  const $images = $('img.lazyload, img.lazy');

  if ('IntersectionObserver' in window) {
    const lazyImageObserver = new IntersectionObserver(
      entries => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            const lazyImage = entry.target;
            loadImages($(lazyImage));
            lazyImageObserver.unobserve(lazyImage);
          }
        }
      },
      {
        rootMargin: '100px'
      }
    );

    $images.each(function () {
      lazyImageObserver.observe(this);
    });
  } else {
    // Fallback to immediate load if Intersection Observer is not supported
    $images.each(function () {
      loadImages($(this));
    });
  }
}

function loadImagesWithMissingClassName() {
  // if an image does not have the  class 'lazyload', load the images anyway
  var missingImages = [];
  missingImages = document.querySelectorAll('.lazy:not(.lazyload, .lazyloaded)');
  if (missingImages) {
    Array.prototype.forEach.call(missingImages, img => {
      img.src = img.dataset.src;
      if (img.dataset.srcset) {
        img.srcset = img.dataset.srcset;
      }
    });
  }
}

module.exports = {
  lazyLoadingHandler: function () {
    $('body').on('triggerLazyLoad', function () {
      lazyLoadImages();
    });
  },
  lazyLoadImages,
  loadImagesWithMissingClassName
};
