'use strict';

var cookieUtil = require('core/components/utilhelper');


/**
 * Checks if the given country code is allowed to ship to.
 * @param {string} countryCode - The country code to check.
 * @returns {boolean} - True if the country is allowed to ship to, false otherwise.
 */
function isAllowedToShipToCountry(customerCurrentIPCountryCode) {
    try {
        var countriesAllowedForShippingJson = window.SitePreferences.storefrontCountriesAllowedForShipping;
        var countriesAllowedForShippingObj = JSON.parse(countriesAllowedForShippingJson);
        // If country is not allowed to ship or if country is allowed to ship but not for the current IP country, display toast.
        if (!countriesAllowedForShippingObj[customerCurrentIPCountryCode]) {
            return false;
        }
        return true;
    } catch (error) {
        console.log('Error occurred in isAllowedToShipToCountry' + error.message);
        return false;
    }
}


/**
 * Displays a toast notification to the user if their current IP country is not allowed to ship to.
 * This function checks the user's IP country using the OneTrust API, and if the country is not allowed to ship to,
 * it triggers the display of a toast notification with the user's country name.
 * The function also sets a cookie to prevent the toast from being displayed again for 365 days if the user closes the toast.
 * @returns {void}
 */
function displayIsNotAllowedToShipToCountryToast() {
    var isNotAllowedToShipToCountryToastDisplayed = cookieUtil.getCookie('IsNotAllowedToShipToCountryToastDisplayed');
    //Early return if the customer has the cookie
    if (isNotAllowedToShipToCountryToastDisplayed) {
        return;
    }

    var tryCount = 0;

    //Wait for One Trust Object Containing Location Data to be available
    var waitOneTrusStubData = setInterval(function () {
        if ('otStubData' in window && window.otStubData.userLocation && window.otStubData.userLocation.country) {
            clearInterval(waitOneTrusStubData);
            var customerCurrentIPCountryCode = otStubData.userLocation.country;

            if (!isAllowedToShipToCountry(customerCurrentIPCountryCode)) {
                var toastMessageURL = new URL($('#unsupportedShippingToastURL').val());
                toastMessageURL.searchParams.set('countryCode', customerCurrentIPCountryCode);
                $.ajax({
                    url: toastMessageURL,
                    type: 'get',
                    success: function (data) {
                        //Triggers a toast notification to display when the customer's IP country is not allowed to ship to.
                        $('<div class="unSupportedCountryToastContainer"></div>').insertBefore('body');
                        var countryToast = $('.unSupportedCountryToastContainer').html(data);
                        var notAllowedToShipToCountryToast = countryToast.find('#notAllowedToShipToCountryToast');

                        if (notAllowedToShipToCountryToast.length) {
                            var countryName = $('#unsupportedShipCountryName').val() || 'your country';
                            $('.country-name').html($('.country-name').html().replace('{{customerCountry}}', countryName));
                            $('.unSupportedCountryToastContainer').addClass('show');
                    
                            //Functionality to close the toast
                            $('.unSupportedCountryToastContainer').find('button.close').click(function () {
                                $('.unSupportedCountryToastContainer').removeClass('show');
                                $('.unSupportedCountryToastContainer').remove();
                                // Set the cookie to prevent showing the toast again only when the customer clicks on the X button
                                cookieUtil.setCookie("IsNotAllowedToShipToCountryToastDisplayed", "true", 365);
                            });
                        }
                    }
                  });
                return;
            } else {
                //Set cookie if the customer is on the allowed country list to avoid running this logic again
                cookieUtil.setCookie("IsNotAllowedToShipToCountryToastDisplayed", "true", 365);
            }

            return;

        } else {
            //Give up after 10s of looking for the One Trust Object
            if (tryCount >= 100) {
                clearInterval(waitOneTrusStubData);
                return;
            }
            tryCount += 1;
        }
    }, 100);
}

displayIsNotAllowedToShipToCountryToast();

