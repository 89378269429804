'use strict';

let base = {};

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
  const $html = $('<div>').append($.parseHTML(html));
  const body = $html.find('.choice-of-bonus-product');
  const footer = $html.find('.modal-footer').children();
  return {
    body: body,
    footer: footer
  };
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
base.getPidValue = function ($el) {
  let pid;
  const $productDetail = $($el).closest('.product-detail');
  const isProductSet = $('.product-set').length;

  if ($('#quickViewModal').hasClass('show') && !isProductSet) {
    pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
  } else if ($('#completeTheLookDrawer').hasClass('show')) {
    pid = $($el).closest('.complete-the-look').data('pid');
  } else if (($('.product-set-detail').length && $productDetail.length && $productDetail.data('pid') !== undefined) || isProductSet) {
    pid = $productDetail.data('pid');
  } else if (isProductSet) {
    pid = $productDetail.find('.product-id').text();
  } else if ($el && $el.closest('.product-tile').length) {
    pid = $el.closest('.product').data('pid');
  } else {
    pid = $('.product-detail:not(.bundle-item)').data('pid');
  }

  return pid;
};

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 * @param {boolean} isPWPModal - is modal
 */
function chooseBonusProducts(data, isPWPModal) {
    const $modalBody = $('.modal-body');
    $modalBody.spinner().start();

    const $chooseBonusProductModal = $('#chooseBonusProductModal');
    if ($chooseBonusProductModal.length !== 0) {
      $chooseBonusProductModal.remove();
    }

    const bonusUrl = data.bonusChoiceRuleBased ? data.showProductsUrlRuleBased : data.showProductsUrlListBased;

    let classList ="modal-dialog choose-bonus-product-dialog quick-view-dialog";
    if(isPWPModal) {
      classList += " pwp-modal";
    }
    const htmlString =
      `<!-- Modal -->
<div class="modal fade bonus-pdt" id="chooseBonusProductModal" tabindex="-1" role="dialog">
    <span class="enter-message sr-only"></span>
    <div class="${classList}"
        data-total-qty="${data.maxBonusItems}"
        data-UUID="${data.uuid}"
        data-pliUUID="${data.pliUUID}"
        data-addToCartUrl="${data.addToCartUrl}"
        data-pageStart="0"
        data-pageSize="${data.pageSize}"
        data-moreURL="${data.showProductsUrlRuleBased}"
        data-bonusChoiceRuleBased="${data.bonusChoiceRuleBased}"
    >
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <span class="gwp_header"></span>
                <button type="button" class="close pull-right float-right" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="cancel-icon svg-36-avenue-Up_Copy svg-36-avenue-Up_Copy-dims"></span>
                </button>
            </div>
            <div class="modal-body"></div>
            <div class="modal-footer"></div>
        </div>
    </div>
</div>`;
    $('body').append(htmlString);

    $.ajax({
      url: bonusUrl,
      method: 'GET',
      dataType: 'json',
      success: function (response) {
        const parsedHtml = parseHtml(response.renderedTemplate);
        $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
        $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
        $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
        $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
        $('#chooseBonusProductModal').modal('show');
        $.each(data.selectedBonusProducts, function (selProdIndex, selProd) {
          $('input[name="chooseBonusproductIds"][data-pid="' + selProd.pid + '"]').prop('checked', true);
        });

        $.spinner().stop();
      },
      error: function () {
        $.spinner().stop();
      }
    });
}

/**
 * Updates the product shipping option DOM elements post Ajax call
 * @param {UpdatedQuantity[]} data - response of an Ajax
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} updateVariantEvent - updateVariantEvent
 */
base.updateShippingOptions = function (data, $productContainer, updateVariantEvent) {
    const $storeDataElement = $productContainer.find('.shipping-option');
    const product = data.product;

    if (data && data.isBopisEnabled && $storeDataElement.length > 0) {
      // update add to cart button name
      const $shopingOptions = $('.shipping-option[data-addtobag-text]');
      if ($shopingOptions.length > 0 && data.product && data.product.availability && !data.product.limitedVarinatQtyUpdated) {
        $shopingOptions.data('addtobag-text', data.product.availability.preorderButtonName || data.product.availability.buttonName);
      }
      // show elements if bopis is enabled
      $storeDataElement.find('.product-pis').removeClass('change-store');
      $storeDataElement.show();
      // update store id in DOM
      if (data.storeInfo) {
        $storeDataElement.find('input.product-pis').attr('data-store-id', data.storeInfo.ID);
      }
      // update store name
      const $changeStore = $('a.change-store');
      if ($changeStore.length > 0) {
        $storeDataElement.find('.product-pis').removeClass('change-store');
        if (data.storeInfo && data.storeInfo.name) {
          $changeStore.text(data.storeInfo.name.toLowerCase());
        }
      }
      // set add to bag button text
      const selectedValue = $storeDataElement.find('input[name=shipto]:checked').val();
      const addToStoreText = $storeDataElement.data('addtostore-text');
      const $addToCartButton = $storeDataElement.closest('.product-detail').find('.add-to-cart');
      if (addToStoreText !== undefined && addToStoreText.length && addToStoreText !== '') {
        if (selectedValue === 'instore') {
          $addToCartButton.html(addToStoreText);
        }
      }

      if (product.productType !== 'variant') {
        // product is a master
        $storeDataElement.find('input[value=shipto]').trigger('click');
      } else if (!data.storeInfo) {
        $storeDataElement.find('.product-pis').addClass('change-store');
        $storeDataElement.find('input[value=instore]').removeAttr('disabled');
        $storeDataElement.find('input[value=shipto]').trigger('click');
      } else if (data.storeInfo && (data.storeInfo.unitsAtStores === 0 || data.storeInfo.unitsAtStores < product.selectedQuantity)) {
        // product is variant and units are unavailable
        if (data.notavailableMsg) {
          $storeDataElement.find('input[value=instore]').siblings('label').find('.main-label').html(data.notavailableMsg);
          $storeDataElement.find('input[value=instore]').attr('disabled', 'disabled');
          $storeDataElement.find('input[value=shipto]').trigger('click');
        }
      } else if (data.storeInfo && data.storeInfo.unitsAtStores > 0 && data.storeInfo.unitsAtStores >= product.selectedQuantity) {
        if (data.availableMsg) {
          $storeDataElement.find('input[value=instore]').siblings('label').find('.main-label').html(data.availableMsg);
          $storeDataElement.find('input[value=instore]').removeAttr('disabled');
          if (!updateVariantEvent) {
            $storeDataElement.find('input[value=instore]').trigger('click');
          }
        }
      }
    } else {
      // hide elements if bopis is disabled
      $storeDataElement.hide();
    }
};

base.methods = {
    editBonusProducts: function (data, isPWPModal) {
      chooseBonusProducts(data, isPWPModal);
    }
};

/**
 * @param {Object} addToCartConfirmationModal popup after product added to cart
 */
base.showAddToCartConfirmation = function (addToCartConfirmationModal) {
  if (!addToCartConfirmationModal) {
    return;
  }
  $('.product-added-to-cart-modal').remove();
  $('.menu-wrapper, .header').append(addToCartConfirmationModal);
  $('.product-added-to-cart-modal').show();
  setTimeout(function () {
    $('.product-added-to-cart-modal').hide();
    const $addToCart = $('.prices-add-to-cart-actions').find('.add-to-cart');
    if ($addToCart.hasClass('ATC-addedToBag')) {
      $addToCart
        .removeClass('disabled')
        .removeAttr('disabled')
        .text($addToCart.attr('temp-btn-data'))
        .removeClass('ATC-addedToBag');
    }
  }, 3000);

  if ($('.minicart .popover').is(':visible')) {
    $('.product-added-to-cart-modal.modal').hide();
  }
}

/**
 * PDP play select video player
 */
base.playVideoPlayer = function () {
  $('.s7videoplayer .s7iconeffect').trigger('click');
};

module.exports = base;
